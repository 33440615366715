import { useMemo } from 'react';

import {
  ProviderViewModel,
  ResourceGroup,
  Subnet,
  VirtualNetwork,
} from '@/react/azure/types';
import { pluralize } from '@/portainer/helpers/strings';

import { Option } from '@@/form-components/PortainerSelect';

import { normalizeLocation } from '../../queries/utils';

export function useSubscriptionResourceGroups(
  subscriptionId?: string,
  resourceGroups?: Record<string, ResourceGroup[]>
) {
  return useMemo(
    () => parseResourceGroupOptions(subscriptionId, resourceGroups),
    [resourceGroups, subscriptionId]
  );
}

export function parseResourceGroupOptions(
  subscriptionId?: string,
  resourceGroups?: Record<string, ResourceGroup[]>
) {
  if (!subscriptionId || !resourceGroups || !resourceGroups[subscriptionId]) {
    return [];
  }

  return resourceGroups[subscriptionId].map(({ name, id }) => ({
    value: id,
    label: name,
  }));
}

export function useSubscriptionLocations(
  subscriptionId?: string,
  containerInstanceProviders?: Record<string, ProviderViewModel | undefined>,
  virtualNetworks?: VirtualNetwork[]
): Option<string>[] {
  return useMemo(() => {
    if (!subscriptionId || !containerInstanceProviders) {
      return [];
    }

    const provider = containerInstanceProviders[subscriptionId];
    if (!provider) {
      return [];
    }

    const locations = provider.locations.map((location) => ({
      value: location,
      label: location,
    }));

    const locationsWithVirtualNetwork = getLocationsVNCount(
      locations,
      virtualNetworks
    );
    return sortLocationsWithVirtualNetworks(
      locations,
      locationsWithVirtualNetwork
    );
  }, [containerInstanceProviders, subscriptionId, virtualNetworks]);
}

/**
 * To help the users find the locations with virtual networks, update the location labels to show the number of virtual networks available at that location.
 */
export function useLocationOptionsWithVNs(
  locationOptions: Option<string>[],
  virtualNetworks: VirtualNetwork[]
) {
  return useMemo(
    () => getLocationsOptionsWithVNs(locationOptions, virtualNetworks),
    [virtualNetworks, locationOptions]
  );
}

function getLocationsOptionsWithVNs(
  locationOptions: Option<string>[],
  virtualNetworks?: VirtualNetwork[]
) {
  const locationsWithVirtualNetwork = getLocationsVNCount(
    locationOptions,
    virtualNetworks
  );
  const updatedLocationOptions = locationOptions.map((location) => {
    const vnCount = locationsWithVirtualNetwork[location.value];
    const label =
      vnCount && location.label
        ? `${location.label} (${vnCount} ${pluralize(
            vnCount,
            'virtual network'
          )} available)`
        : location.label;
    return {
      ...location,
      label,
    };
  });
  return sortLocationsWithVirtualNetworks(
    updatedLocationOptions,
    locationsWithVirtualNetwork
  );
}

function getLocationsVNCount(
  locationOptions: Option<string>[],
  virtualNetworks?: VirtualNetwork[]
) {
  const locationsWithVirtualNetwork = locationOptions.reduce<
    Record<string, number>
  >((acc, locationOption) => {
    const virtualNetworksAtLocation = virtualNetworks?.filter((vn) => {
      const subnetsWithoutIpConfig = filterOutSubnetsWithIpConfig(
        vn.properties.subnets
      );
      return (
        normalizeLocation(vn.location) ===
          normalizeLocation(locationOption.value) &&
        subnetsWithoutIpConfig.length >= 1
      );
    });
    acc[locationOption.value] = virtualNetworksAtLocation?.length ?? 0;
    return acc;
  }, {});
  return locationsWithVirtualNetwork;
}

/**
 * Sort locations by the number of virtual networks available at that location.
 */
function sortLocationsWithVirtualNetworks(
  locations: Option<string>[],
  locationsWithVirtualNetwork: Record<string, number>
) {
  return locations.sort((a, b) => {
    const vnCountA = locationsWithVirtualNetwork[a.value];
    const vnCountB = locationsWithVirtualNetwork[b.value];
    return vnCountB - vnCountA;
  });
}

/**
 * subnets with ipConfig can't have have delegations added to them, so only include ones without ipConfig
 */
export function filterOutSubnetsWithIpConfig(subnets: Subnet[]) {
  return subnets.filter(
    (subnet) => !subnet.properties.ipConfigurations?.length
  );
}
