import { useState } from 'react';

import { Environment } from '@/react/portainer/environments/types';
import { CustomTemplate } from '@/react/portainer/templates/custom-templates/types';

import { AgentForm } from '../shared/AgentForm';

import { deployments, DeploymentScripts } from './DeploymentScripts';

interface Props {
  onCreate(environment: Environment): void;
  customTemplates: CustomTemplate[];
}

export function AgentPanel({ onCreate, customTemplates }: Props) {
  const [deployType, setDeployType] = useState(deployments[0].id);
  const defaultPort = deployType === 'k8sNodePort' ? '30778' : '9001';
  return (
    <>
      <DeploymentScripts
        deployType={deployType}
        setDeployType={setDeployType}
      />

      <div className="mt-5">
        <AgentForm
          onCreate={onCreate}
          customTemplates={customTemplates}
          envDefaultPort={defaultPort}
        />
      </div>
    </>
  );
}
