import { useQueries } from '@tanstack/react-query';

import { withError } from '@/react-tools/react-query';
import { EnvironmentId } from '@/react/portainer/environments/types';

import { ContainerGroup } from '../types';
import { parseContainerGroupId } from '../utils';

import { queryKeys } from './query-keys';
import { getContainerGroup } from './useContainerGroup';

/**
 * useContainerGroups exists to fetch the instanceView in the container group properties. The list request doesn't have this property
 *
 * https://learn.microsoft.com/en-us/rest/api/container-instances/container-groups/get?view=rest-container-instances-2023-05-01&tabs=HTTP#containergroupproperties.properties.instanceview
 */
export function useContainerGroupsState(
  environmentId: EnvironmentId,
  containerGroups: ContainerGroup[]
) {
  const queries = useQueries({
    queries: containerGroups.map((containerGroup) => {
      const { subscriptionId, resourceGroupName } = parseContainerGroupId(
        containerGroup.id ?? '-'
      );
      return {
        queryKey: queryKeys.containerGroup(
          environmentId,
          subscriptionId,
          resourceGroupName,
          containerGroup.name,
          'status'
        ),
        queryFn: async () => {
          // try catch error handling is already covered in getContainerGroup
          const containerGroupDetails = await getContainerGroup(
            environmentId,
            subscriptionId,
            resourceGroupName,
            containerGroup.name
          );
          return (
            containerGroupDetails.properties?.instanceView?.state ?? 'Unknown'
          );
        },
        ...withError('Unable to retrieve Azure container group'),
      };
    }),
  });

  return {
    // some entries might be undefined while loading, but the indeces always match
    data: queries.map((q) => q.data),
    isLoading: queries.some((q) => q.isLoading),
    isError: queries.some((q) => q.isError),
  };
}
