import { useFormikContext, Field } from 'formik';
import { Fragment } from 'react';

import { useSetAvailableOption } from '@/react/portainer/environments/wizard/EnvironmentsCreationView/WizardKaaS/useSetAvailableOption';

import { FormControl } from '@@/form-components/FormControl';
import { PortainerSelect } from '@@/form-components/PortainerSelect';
import { TextTip } from '@@/Tip/TextTip';
import { Switch } from '@@/form-components/SwitchField/Switch';
import { Input } from '@@/form-components/Input';
import { FormSection } from '@@/form-components/FormSection';

import { ContainerInstanceFormValues } from '../../types';

const previewLocations = [
  'East US',
  'West Europe',
  'West US 2',
  'Southeast Asia',
  'Central India',
];

const gpuOptions = [{ label: 'V100', value: 'V100' }];

export function GPUFormSection() {
  const { values, setFieldValue, errors } =
    useFormikContext<ContainerInstanceFormValues>();
  useSetAvailableOption(gpuOptions, values.gpu.sku || '', 'gpu.sku');

  return (
    <FormSection title="GPUs">
      <TextTip color="blue">
        <>
          <p>
            GPUs are currently a{' '}
            <a
              href="https://learn.microsoft.com/en-us/azure/container-instances/container-instances-gpu#prerequisites"
              target="_blank"
              rel="noopener noreferrer"
              data-cy="gpu-prerequisites-link"
            >
              preview feature
            </a>{' '}
            for Azure Container Instances. Make sure that you have access to
            GPUs to use this feature.{' '}
          </p>
          <p>
            {/* Give an info message about where GPUs are available but don't restrict the GPU options incase the locations expand */}
            During the preview, GPUs are only available in{' '}
            {previewLocations.map((location, index) => (
              <Fragment key={location}>
                <b>{location}</b>
                {index < previewLocations.length - 2 && ', '}
                {index === previewLocations.length - 2 && ' and '}
              </Fragment>
            ))}
            .
          </p>
        </>
      </TextTip>
      <FormControl label="Enable GPUs" inputId="enable-gpus-checkbox">
        <Field
          name="gpu"
          as={Switch}
          id="is-gpu-enabled-checkbox"
          checked={values.enableGPU}
          onChange={(enabled: boolean) => setFieldValue('enableGPU', enabled)}
          data-cy="is-gpu-enabled-checkbox"
        />
      </FormControl>

      {values.enableGPU && (
        <>
          <FormControl
            label="GPU type"
            inputId="gpu-type-select"
            errors={errors.gpu?.sku}
          >
            <Field
              name="gpu.sku"
              component={PortainerSelect}
              value={values.gpu.sku}
              noOptionsMessage={() =>
                'No GPUs available for the selected location.'
              }
              placeholder="Select a GPU type"
              options={gpuOptions}
              onChange={(selectedOption: string) =>
                setFieldValue('gpu.sku', selectedOption)
              }
              data-cy="gpu-type-select"
            />
          </FormControl>
          <FormControl
            label="GPU count"
            inputId="gpu-count-input"
            errors={errors.gpu?.count}
          >
            <Field
              name="gpu.count"
              as={Input}
              min={1}
              id="gpu-count-input"
              type="number"
              placeholder="1"
              data-cy="gpu-count-input"
            />
          </FormControl>
        </>
      )}
    </FormSection>
  );
}
