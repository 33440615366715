import { PageHeader } from '@@/PageHeader';

import { ContainersDatatable } from './ContainersDatatable';

export function ListView() {
  return (
    <>
      <PageHeader
        title="Container list"
        breadcrumbs="Container instances"
        reload
      />

      <ContainersDatatable />
    </>
  );
}
