import { StatusBadgeColor } from '@@/StatusBadge';

import { ContainerGroup, ProvisioningState } from './types';

export function parseContainerGroupId(id: string) {
  const match = id.match(
    /^\/subscriptions\/(.+)\/resourceGroups\/(.+)\/providers\/(.+)\/containerGroups\/(.+)$/
  );

  if (!match) {
    throw new Error('container id is missing details');
  }

  const [, subscriptionId, resourceGroupName, , containerGroupId] = match;

  return {
    subscriptionId,
    resourceGroupName,
    containerGroupId,
  };
}

export function parseSubnetId(id: string) {
  const match = id.match(
    /^\/subscriptions\/(.+)\/resourceGroups\/(.+)\/providers\/Microsoft.Network\/virtualNetworks\/(.+)\/subnets\/(.+)$/
  );

  if (!match) {
    throw new Error('subnet id is missing details');
  }

  const [, subscriptionId, resourceGroupName, virtualNetworkName, subnetName] =
    match;

  return {
    subscriptionId,
    resourceGroupName,
    virtualNetworkName,
    subnetName,
  };
}

export function getPorts(containerGroup?: ContainerGroup) {
  if (!containerGroup || !containerGroup.properties) {
    return [];
  }
  const addressPorts = containerGroup.properties.ipAddress
    ? containerGroup.properties.ipAddress.ports
    : [];
  const container = containerGroup.properties.containers.length
    ? containerGroup.properties.containers[0]
    : null;
  const containerPorts = container ? container.properties.ports : [];

  return addressPorts.map((binding, index) => {
    const port = containerPorts[index] ? containerPorts[index].port : undefined;
    return {
      container: port,
      host: binding.port,
      protocol: binding.protocol,
    };
  });
}

export const statusColor: Record<ProvisioningState, StatusBadgeColor> = {
  Succeeded: 'success',
  Running: 'success',
  Created: 'success',
  Creating: 'info',
  Updating: 'info',
  Pending: 'info',
  Stopped: 'warning',
  Unknown: 'warning',
  Failed: 'danger',
};
