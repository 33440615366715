import { useQuery } from '@tanstack/react-query';

import { EnvironmentId } from '@/react/portainer/environments/types';
import { withError } from '@/react-tools/react-query';
import axios, { parseAxiosError } from '@/portainer/services/axios';

import { containerGroupApiVersion, ContainerGroup } from '../types';

import { queryKeys } from './query-keys';
import { buildContainerGroupUrl } from './utils';

export function useContainerGroup<T = ContainerGroup>(
  environmentId: EnvironmentId,
  subscriptionId: string,
  resourceGroupName: string,
  containerGroupName: string,
  options?: {
    select: (container: ContainerGroup) => T;
    refetchInterval: number;
  }
) {
  return useQuery(
    queryKeys.containerGroup(
      environmentId,
      subscriptionId,
      resourceGroupName,
      containerGroupName
    ),
    () =>
      getContainerGroup(
        environmentId,
        subscriptionId,
        resourceGroupName,
        containerGroupName
      ),
    {
      ...withError('Unable to retrieve Azure container group'),
      ...options,
    }
  );
}

export async function getContainerGroup(
  environmentId: EnvironmentId,
  subscriptionId: string,
  resourceGroupName: string,
  containerGroupName: string
) {
  try {
    const { data } = await axios.get<ContainerGroup>(
      buildContainerGroupUrl(
        environmentId,
        subscriptionId,
        resourceGroupName,
        containerGroupName
      ),
      { params: { 'api-version': containerGroupApiVersion } }
    );

    return data;
  } catch (e) {
    throw parseAxiosError(e, 'Unable to get Azure container group');
  }
}
