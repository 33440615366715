import { useField } from 'formik';

import { FormSection } from '@@/form-components/FormSection';
import { SwitchField } from '@@/form-components/SwitchField';

export function KubeShellSection() {
  const [{ value }, , { setValue }] = useField<boolean>('disableKubeShell');

  return (
    <FormSection title="KubeShell">
      <div className="form-group">
        <div className="col-sm-12">
          <SwitchField
            label="Disable KubeShell access for non-admin users"
            data-cy="kube-settings-disable-kube-shell"
            checked={value}
            name="disableKubeconfigDownload"
            onChange={(value) => setValue(value)}
            labelClass="col-sm-3 col-lg-2"
            tooltip="Prevents users without an environment admin role from accessing the KubeShell feature."
          />
        </div>
      </div>
    </FormSection>
  );
}
