import { Box } from 'lucide-react';
import { useRouter } from '@uirouter/react';

import { useEnvironmentId } from '@/react/hooks/useEnvironmentId';
import { Authorized, useAuthorizations } from '@/react/hooks/useUser';

import { Datatable, TableSettingsMenu } from '@@/datatables';
import {
  BasicTableSettings,
  RefreshableTableSettings,
  createPersistedStore,
  refreshableSettings,
} from '@@/datatables/types';
import { useTableState } from '@@/datatables/useTableState';
import { TableSettingsMenuAutoRefresh } from '@@/datatables/TableSettingsMenuAutoRefresh';
import { AddButton } from '@@/buttons';

import { useContainerGroups } from '../../queries/useContainerGroups';
import { useResourceGroups } from '../../queries/useResourceGroups';
import { useSubscriptions } from '../../queries/useSubscriptions';
import { useContainerGroupsState } from '../../queries/useContainerGroupsState';
import { ContainerActions } from '../ContainerActions';
import { useContainerGroupRows } from '../useContainerGroupRows';

import { useColumns } from './columns';

interface TableSettings extends BasicTableSettings, RefreshableTableSettings {}
const tableKey = 'containergroups';
const settingsStore = createPersistedStore<TableSettings>(
  tableKey,
  'name',
  (set) => ({
    ...refreshableSettings(set),
  })
);

export function ContainersDatatable() {
  const tableState = useTableState(settingsStore, tableKey);
  const router = useRouter();

  // fetch info for the row data
  const environmentId = useEnvironmentId();
  const subscriptionsQuery = useSubscriptions(environmentId);
  const containerGroupsQuery = useContainerGroups(
    environmentId,
    subscriptionsQuery.data,
    subscriptionsQuery.isSuccess,
    { refetchInterval: tableState.autoRefreshRate * 1000 }
  );
  const containerGroupsStatusQuery = useContainerGroupsState(
    environmentId,
    containerGroupsQuery.data
  );
  const resourceGroupsQuery = useResourceGroups(
    environmentId,
    subscriptionsQuery.data
  );
  const allResourceGroups = Object.values(resourceGroupsQuery.data).flat();
  const containerGroupRows = useContainerGroupRows(
    containerGroupsQuery.data,
    allResourceGroups,
    containerGroupsStatusQuery.data
  );
  const { authorized: hasEditOrDeleteAuth } = useAuthorizations([
    'AzureContainerGroupAction',
    'AzureContainerGroupDelete',
  ]);

  return (
    <Datatable
      dataset={containerGroupRows}
      columns={useColumns(containerGroupsStatusQuery.isLoading)}
      settingsManager={tableState}
      isLoading={
        subscriptionsQuery.isLoading ||
        containerGroupsQuery.isLoading ||
        resourceGroupsQuery.isLoading
      }
      title="Containers"
      titleIcon={Box}
      getRowId={(container) => container.id ?? '-'}
      data-cy="containers-datatable"
      renderTableActions={(selectedItems) => (
        <>
          <ContainerActions
            selectedItems={selectedItems}
            environmentId={environmentId}
            onDeleteSuccess={() => router.stateService.reload()}
          />
          <Authorized authorizations="AzureContainerGroupCreate">
            <AddButton data-cy="add-container-button">Add container</AddButton>
          </Authorized>
        </>
      )}
      disableSelect={!hasEditOrDeleteAuth}
      renderTableSettings={() => (
        <TableSettingsMenu>
          <TableSettingsMenuAutoRefresh
            value={tableState.autoRefreshRate}
            onChange={(value) => tableState.setAutoRefreshRate(value)}
          />
        </TableSettingsMenu>
      )}
    />
  );
}
