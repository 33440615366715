import { Field, useFormikContext } from 'formik';

import { FormSection } from '@@/form-components/FormSection';
import { TextTip } from '@@/Tip/TextTip';
import { FormControl } from '@@/form-components/FormControl';
import { Switch } from '@@/form-components/SwitchField/Switch';
import { isArrayErrorType } from '@@/form-components/formikUtils';
import { Input } from '@@/form-components/Input';

import { ContainerInstanceFormValues } from '../../types';

export function VolumeFormSection() {
  const { values, setFieldValue, errors } =
    useFormikContext<ContainerInstanceFormValues>();
  const volumeArrayErrors = isArrayErrorType(errors.volumes)
    ? errors.volumes
    : [];
  return (
    <FormSection title="Volumes">
      <TextTip color="blue">
        Ensure you have created an{' '}
        <a
          href="https://learn.microsoft.com/en-us/azure/storage/files/storage-how-to-create-file-share?tabs=azure-portal"
          target="_blank"
          rel="noopener noreferrer"
          data-cy="azure-file-share-link"
        >
          Azure file share
        </a>{' '}
        with{' '}
        <a
          href="https://learn.microsoft.com/en-us/azure/storage/common/storage-account-keys-manage?tabs=azure-portal#view-account-access-keys"
          target="_blank"
          rel="noopener noreferrer"
          data-cy="azure-file-share-link"
        >
          access keys
        </a>{' '}
        before adding a volume.
      </TextTip>
      <FormControl
        label="Enable volumes"
        inputId="enable-volumes-checkbox"
        errors={errors.enableVolumes}
      >
        <Field
          name="enableVolumes"
          as={Switch}
          id="is-volumes-enabled-checkbox"
          checked={values.enableVolumes}
          onChange={(enabled: boolean) =>
            setFieldValue('enableVolumes', enabled)
          }
          data-cy="is-volumes-enabled-checkbox"
        />
      </FormControl>
      {values.enableVolumes && (
        <>
          <FormControl
            required
            label="File share name"
            inputId="share-name-input"
            errors={volumeArrayErrors[0]?.shareName}
          >
            <Field
              name="volumes[0].shareName"
              placeholder="e.g. myshare"
              as={Input}
              id="share-name-input"
              data-cy="share-name-input"
            />
          </FormControl>
          <FormControl
            required
            label="Storage account"
            inputId="storage-account-name-input"
            tooltip="The name of the storage account that the file share is in"
            errors={volumeArrayErrors[0]?.storageAccountName}
          >
            <Field
              name="volumes[0].storageAccountName"
              placeholder="e.g. mystorageaccount"
              as={Input}
              id="storage-account-name-input"
              data-cy="storage-account-name-input"
            />
          </FormControl>
          <FormControl
            required
            label="Storage account key"
            inputId="storage-account-key-input"
            tooltip="The access key of the storage account"
            errors={volumeArrayErrors[0]?.storageAccountKey}
          >
            <Field
              name="volumes[0].storageAccountKey"
              placeholder="e.g. GbAl...RNsEtW=="
              as={Input}
              type="password"
              autoComplete="off"
              id="storage-account-key-input"
              data-cy="storage-account-key-input"
            />
          </FormControl>
          <FormControl
            required
            label="Mount path"
            inputId="mount-path-input"
            tooltip="The path within the container where the volume should be mounted"
            errors={volumeArrayErrors[0]?.mountPath}
          >
            <Field
              name="volumes[0].mountPath"
              placeholder="e.g. /mnt/share"
              as={Input}
              id="mount-path-input"
              data-cy="mount-path-input"
            />
          </FormControl>
        </>
      )}
    </FormSection>
  );
}
