import { useEffect } from 'react';
import { useFormikContext } from 'formik';

import { Option } from '@@/form-components/Input/Select';
import { GroupOption } from '@@/form-components/PortainerSelect';

/**
 * set the fieldName to an available value if the value is not found in the new list of available options. IMPORTANT: the options should be memoized so that the useEffect does not run every time the component re-renders
 */
export function useSetAvailableOption<T extends string | number>(
  options: Option<T>[] | GroupOption<T>[] | undefined,
  value: T,
  fieldName: string,
  defaultValue?: T
) {
  const { setFieldValue } = useFormikContext();

  useEffect(() => {
    if (!options || options.length === 0) {
      setFieldValue(fieldName, '');
      return;
    }
    if (valueFound(options, value)) {
      return;
    }
    if (defaultValue && valueFound<T>(options, defaultValue)) {
      setFieldValue(fieldName, defaultValue);
      return;
    }

    // set the value to the first available option
    if ('value' in options[0]) {
      setFieldValue(fieldName, options[0]?.value || '');
      return;
    }
    setFieldValue(fieldName, options[0].options[0]?.value || '');
  }, [options, value, setFieldValue, defaultValue, fieldName]);
}

function valueFound<T extends string | number>(
  options: Option<T>[] | GroupOption<T>[],
  value: T
) {
  return options.find((option) => {
    if ('value' in option) {
      return option.value === value;
    }
    return option.options.some((subOption) => subOption.value === value);
  });
}
