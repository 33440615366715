import _ from 'lodash-es';
import { KubernetesSecretCreatePayload, KubernetesSecretUpdatePayload } from 'Kubernetes/models/secret/payloads';
import { KubernetesApplicationSecret } from 'Kubernetes/models/secret/models';
import { KubernetesPortainerConfigurationDataAnnotation } from 'Kubernetes/models/configuration/models';
import { KubernetesConfigurationFormValuesEntry } from 'Kubernetes/models/configuration/formvalues';
import { KubernetesSecretTypeOptions } from 'Kubernetes/models/configuration/models';
import { prepareAnnotations } from '@/react/kubernetes/utils';
import { configurationOwnerUsernameLabel, configurationOwnerIdLabel } from '@/react/kubernetes/configs/constants';
import { appOwnerIdLabel } from '@/react/kubernetes/applications/constants';
import KubernetesAnnotationsUtils from './annotations';

class KubernetesSecretConverter {
  static createPayload(secret) {
    const res = new KubernetesSecretCreatePayload();
    res.metadata.name = secret.Name;
    res.metadata.namespace = secret.Namespace.Name;
    res.type = secret.Type;
    const configurationOwner = _.truncate(secret.ConfigurationOwner, { length: 63, omission: '' });
    res.metadata.labels[configurationOwnerUsernameLabel] = configurationOwner;
    res.metadata.labels[configurationOwnerIdLabel] = secret.ConfigurationOwnerId;

    let annotation = '';
    _.forEach(secret.Data, (entry) => {
      if (entry.IsBinary) {
        res.data[entry.Key] = entry.Value;
        annotation += annotation !== '' ? '|' + entry.Key : entry.Key;
      } else {
        res.stringData[entry.Key] = entry.Value;
      }
    });

    res.metadata.annotations = prepareAnnotations(secret.Annotations);

    if (annotation !== '') {
      res.metadata.annotations[KubernetesPortainerConfigurationDataAnnotation] = annotation;
    }

    return res;
  }

  static updatePayload(secret) {
    const res = new KubernetesSecretUpdatePayload();
    res.metadata.name = secret.Name;
    res.metadata.namespace = secret.Namespace;
    res.type = secret.Type;
    res.metadata.labels = secret.Labels || {};
    res.metadata.labels[configurationOwnerUsernameLabel] = secret.ConfigurationOwner;
    res.metadata.labels[configurationOwnerIdLabel] = secret.ConfigurationOwnerId;

    let annotation = '';
    _.forEach(secret.Data, (entry) => {
      if (entry.IsBinary) {
        res.data[entry.Key] = entry.Value;
        annotation += annotation !== '' ? '|' + entry.Key : entry.Key;
      } else {
        res.stringData[entry.Key] = entry.Value;
      }
    });

    res.metadata.annotations = prepareAnnotations(secret.Annotations);

    if (annotation !== '') {
      res.metadata.annotations[KubernetesPortainerConfigurationDataAnnotation] = annotation;
    }

    return res;
  }

  static apiToSecret(payload, yaml) {
    // the secret could have the appOwnerIdLabel if created from a manifest
    function getSecretOwnerId() {
      if (payload.metadata.labels) {
        return payload.metadata.labels[configurationOwnerIdLabel] || payload.metadata.labels[appOwnerIdLabel];
      }
      return '';
    }

    const res = new KubernetesApplicationSecret();
    res.Id = payload.metadata.uid;
    res.Name = payload.metadata.name;
    res.Namespace = payload.metadata.namespace;
    res.Type = payload.type;
    res.ConfigurationOwner = payload.metadata.labels ? payload.metadata.labels[configurationOwnerUsernameLabel] : '';
    res.ConfigurationOwnerId = getSecretOwnerId();
    res.CreationDate = payload.metadata.creationTimestamp;
    res.Annotations = payload.metadata.annotations ? KubernetesAnnotationsUtils.apiToFormValueAnnotations(payload.metadata.annotations) : [];

    res.Labels = payload.metadata.labels || {};
    res.IsRegistrySecret = payload.metadata.annotations && !!payload.metadata.annotations['portainer.io/registry.id'];

    res.Yaml = yaml ? yaml.data : '';

    res.SecretType = payload.type;

    res.Data = _.map(payload.data, (value, key) => {
      const annotations = payload.metadata.annotations ? payload.metadata.annotations[KubernetesPortainerConfigurationDataAnnotation] : '';
      const entry = new KubernetesConfigurationFormValuesEntry();
      entry.Key = key;
      entry.IsBinary = _.includes(annotations, entry.Key);

      if (!entry.IsBinary) {
        entry.Value = atob(value);
      } else {
        entry.Value = value;
      }
      return entry;
    });
    res.data = res.Data;

    return res;
  }

  static configurationFormValuesToSecret(formValues) {
    const res = new KubernetesApplicationSecret();
    res.Name = formValues.Name;
    res.Namespace = formValues.ResourcePool;
    res.Type = formValues.Type;
    res.ConfigurationOwner = formValues.ConfigurationOwner;
    res.ConfigurationOwnerId = formValues.ConfigurationOwnerId;
    res.Data = formValues.Data;

    if (formValues.Type === KubernetesSecretTypeOptions.CUSTOM.value) {
      res.Type = formValues.customType;
    }
    res.Annotations = formValues.Annotations;
    if (formValues.Type === KubernetesSecretTypeOptions.SERVICEACCOUNTTOKEN.value) {
      const serviceAccountAnnotation = formValues.Annotations.find((a) => a.Key === 'kubernetes.io/service-account.name');
      if (!serviceAccountAnnotation) {
        res.Annotations.push({ Key: 'kubernetes.io/service-account.name', Value: formValues.ServiceAccountName });
      }
    }
    return res;
  }
}

export default KubernetesSecretConverter;
