import { ContainerGroup } from '@/react/azure/types';

import { Badge } from '@@/Badge';

import { columnHelper } from './helper';

export const tags = columnHelper.accessor(
  (containerGroup: ContainerGroup) =>
    Object.entries(containerGroup.tags || {})
      .map(([name, value]) => `${name}: ${value}`)
      .join('\n'),
  {
    header: 'Tags',
    cell: ({ row: { original: containerGroup } }) => {
      const tagsArr = Object.entries(containerGroup.tags || {}).map(
        ([name, value]) => `${name}: ${value}`
      );
      if (tagsArr.length === 0) {
        return '-';
      }
      return (
        <div className="flex gap-1 flex-wrap">
          {tagsArr.map((tag) => (
            <Badge key={tag} type="info">
              {tag}
            </Badge>
          ))}
        </div>
      );
    },
  }
);
