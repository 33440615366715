import { useFormikContext, Field } from 'formik';
import { Fragment, useMemo } from 'react';

import { useVirtualNetworks } from '@/react/azure/queries/useVirtualNetworks';
import { useSetAvailableOption } from '@/react/portainer/environments/wizard/EnvironmentsCreationView/WizardKaaS/useSetAvailableOption';

import { FormControl } from '@@/form-components/FormControl';
import {
  PortainerSelect,
  Option,
  GroupOption,
} from '@@/form-components/PortainerSelect';
import { TextTip } from '@@/Tip/TextTip';
import { FormSectionTitle } from '@@/form-components/FormSectionTitle';
import { Switch } from '@@/form-components/SwitchField/Switch';

import { ContainerInstanceFormValues, VirtualNetwork } from '../../types';
import { normalizeLocation } from '../../queries/utils';
import { parseSubnetId } from '../../utils';

import { PortsMappingField } from './PortsMappingField';
import { filterOutSubnetsWithIpConfig } from './utils';

interface Props {
  environmentId: number;
  locationOptions: Option<string>[];
}

export function NetworksFormSection({ environmentId, locationOptions }: Props) {
  const { values, setFieldValue, errors } =
    useFormikContext<ContainerInstanceFormValues>();
  const { subscription, location } = values;
  const virtualNetworksQuery = useVirtualNetworks(
    environmentId,
    subscription || ''
  );
  const virtualNetworks = virtualNetworksQuery.data;

  const { virtualNetworkOptions, locationsWithVirtualNetwork } =
    useVirtualNetworkOptions(locationOptions, location || '', virtualNetworks);
  const virtualNetworksWithSubnet =
    useVirtualNetworksWithSubnet(virtualNetworks);
  const subnetOptions = useSubnetOptions(
    values.virtualNetwork || '',
    virtualNetworks
  );

  useSetAvailableOption(
    virtualNetworkOptions,
    values.virtualNetwork || '',
    'virtualNetwork'
  );
  useSetAvailableOption(subnetOptions, values.subnet || '', 'subnet');

  return (
    <>
      <FormSectionTitle>Network Settings</FormSectionTitle>
      <TextTip color="blue" className="mb-2">
        This will automatically deploy an instance with a public IP address. It
        is highly recommended to host it in a private network.
      </TextTip>
      <FormControl
        label="Private Network"
        inputId="is-private-network-checkbox"
      >
        <Field
          name="isPrivate"
          as={Switch}
          id="is-private-network-checkbox"
          checked={!values.allocatePublicIP}
          onChange={(enabled: boolean) =>
            setFieldValue('allocatePublicIP', !enabled)
          }
          data-cy="private-network-switch"
        />
      </FormControl>

      {!values.allocatePublicIP && (
        <>
          <FormControl
            label="Virtual Network"
            inputId="virtualNetwork-input"
            isLoading={virtualNetworksQuery.isLoading}
            loadingText="Loading virtual networks..."
            errors={errors.virtualNetwork}
          >
            {locationsWithVirtualNetwork.length >= 1 &&
              !values.virtualNetwork && (
                <TextTip color="blue">
                  There are virtual networks available in{' '}
                  {locationsWithVirtualNetwork.map((location, index) => (
                    <Fragment key={location.label}>
                      <b>{location.label}</b>
                      {index < locationsWithVirtualNetwork.length - 2 && ', '}
                      {index === locationsWithVirtualNetwork.length - 2 &&
                        ' and '}
                    </Fragment>
                  ))}
                  .
                </TextTip>
              )}
            <Field
              name="virtualNetwork"
              component={PortainerSelect}
              value={values.virtualNetwork}
              noOptionsMessage={() =>
                'No virtual networks available for the selected location.'
              }
              // use 'key' to tell react to remount the component when the value goes to undefined, to show the empty value placeholder
              key={!!values.virtualNetwork}
              placeholder="Select a virtual network"
              options={virtualNetworkOptions}
              onChange={(selectedOption: string) => {
                setFieldValue('virtualNetwork', selectedOption);
                // Reset subnet field in Formik state when virtual network changes
                setFieldValue('subnet', '');
              }}
              data-cy="virtual-network-select"
            />
            {locationsWithVirtualNetwork.length === 0 && (
              <TextTip color="red">
                There are no virtual networks available in any location. Please
                try another subscription, or create a virtual network in the
                Azure portal.
              </TextTip>
            )}
          </FormControl>
          <FormControl
            label="Subnet"
            inputId="subnet-input"
            isLoading={virtualNetworksQuery.isLoading}
            loadingText="Loading subnets..."
            errors={errors.subnet}
          >
            <Field
              name="subnet"
              component={PortainerSelect}
              value={values.subnet}
              noOptionsMessage={() =>
                'No subnets available for the selected virtual network.'
              }
              // use 'key' to tell react to remount the component when the value goes to undefined, to show the empty value placeholder
              key={!!values.virtualNetwork}
              placeholder="Select a subnet"
              options={subnetOptions}
              onChange={(selectedOption: string) =>
                setFieldValue('subnet', selectedOption)
              }
              data-cy="subnet-select"
            />
            {/* is no subnet available when there are networks which are avaiable */}
            {virtualNetworksWithSubnet.length === 0 &&
              locationsWithVirtualNetwork.length !== 0 && (
                <TextTip color="red">
                  There are no subnets available in any available virtual
                  network. Please try another subscription or location, or
                  create a subnet in the Azure portal.
                </TextTip>
              )}
          </FormControl>
        </>
      )}
      <PortsMappingField
        value={values.ports}
        onChange={(value) => setFieldValue('ports', value)}
        errors={errors.ports}
      />
    </>
  );
}

function useVirtualNetworkOptions(
  locationOptions: Option<string>[],
  location: string,
  virtualNetworks?: VirtualNetwork[]
) {
  return useMemo(() => {
    if (!virtualNetworks) {
      return {
        locationsWithVirtualNetwork: [],
      };
    }
    const virtualNetworkOptions = virtualNetworks
      ?.filter((vn) => {
        const subnetsWithoutIpConfig = filterOutSubnetsWithIpConfig(
          vn.properties.subnets
        );
        return (
          normalizeLocation(vn.location) ===
            normalizeLocation(location || '') &&
          subnetsWithoutIpConfig.length >= 1
        );
      })
      .map((vn) => ({
        label: vn.name,
        value: vn.id,
      }));
    const locationsWithVirtualNetwork = locationOptions.filter((location) =>
      virtualNetworks.some(
        (vn) =>
          normalizeLocation(vn.location) === normalizeLocation(location.value)
      )
    );
    return { virtualNetworkOptions, locationsWithVirtualNetwork };
  }, [location, locationOptions, virtualNetworks]);
}

function useVirtualNetworksWithSubnet(
  virtualNetworks?: VirtualNetwork[]
): VirtualNetwork[] {
  return useMemo(
    () =>
      virtualNetworks?.filter((vn) => {
        const subnetsWithoutIpConfig = filterOutSubnetsWithIpConfig(
          vn.properties.subnets
        );
        return subnetsWithoutIpConfig.length >= 1;
      }) || [],
    [virtualNetworks]
  );
}

function useSubnetOptions(
  virtualNetworkValue: string,
  virtualNetworks?: VirtualNetwork[]
) {
  return useMemo(() => {
    if (!virtualNetworks) {
      return [];
    }
    const selectedVirtualNetwork = virtualNetworks.find(
      (vn) => vn.id === virtualNetworkValue
    );
    if (!selectedVirtualNetwork) {
      return [];
    }

    const filteredSubnets = filterOutSubnetsWithIpConfig(
      selectedVirtualNetwork?.properties.subnets
    );

    const subnetOptions = filteredSubnets.reduce<GroupOption<string>[]>(
      (groupedOptions, subnet) => {
        const { resourceGroupName } = parseSubnetId(subnet.id);
        const existingGroup = groupedOptions.find(
          (group) => group.label === resourceGroupName
        );
        if (existingGroup) {
          existingGroup.options.push({
            label: subnet.name,
            value: subnet.id,
          });
          return groupedOptions;
        }
        groupedOptions.push({
          label: resourceGroupName,
          options: [
            {
              label: subnet.name,
              value: subnet.id,
            },
          ],
        });
        return groupedOptions;
      },
      []
    );
    return subnetOptions;
  }, [virtualNetworkValue, virtualNetworks]);
}
