import { useRouter } from '@uirouter/react';
import { Play, Square, RefreshCw } from 'lucide-react';

import { Authorized } from '@/react/hooks/useUser';
import { EnvironmentId } from '@/react/portainer/environments/types';

import { ButtonGroup, LoadingButton } from '@@/buttons';
import { DeleteButton } from '@@/buttons/DeleteButton';

import { useContainerGroupsAction } from '../queries/useContainerAction';
import { useRemoveContainerGroups } from '../queries/useRemoveContainerGroups';
import { ContainerGroup } from '../types';

export function ContainerActions({
  selectedItems,
  environmentId,
  onDeleteSuccess,
}: {
  selectedItems: ContainerGroup[];
  environmentId: EnvironmentId;
  onDeleteSuccess: () => void;
}) {
  const router = useRouter();
  const deleteMutation = useRemoveContainerGroups(environmentId);
  const startMutation = useContainerGroupsAction(environmentId, 'start');
  const stopMutation = useContainerGroupsAction(environmentId, 'stop');
  const restartMutation = useContainerGroupsAction(environmentId, 'restart');
  const hasStoppedItemsSelected = selectedItems.some(
    (row) => row.properties?.instanceView?.state === 'Stopped'
  );
  const hasRunningItemsSelected = selectedItems.some(
    (row) =>
      row.properties?.instanceView?.state === 'Running' ||
      row.properties?.instanceView?.state === 'Succeeded'
  );
  const selectedCount = selectedItems.length;
  return (
    <ButtonGroup>
      <Authorized authorizations="AzureContainerGroupAction">
        <LoadingButton
          color="light"
          data-cy="start-azure-container-button"
          onClick={() =>
            startMutation.mutate(selectedItems, {
              onSuccess: () => router.stateService.reload(),
            })
          }
          disabled={selectedCount === 0 || !hasStoppedItemsSelected}
          icon={Play}
          loadingText="Starting"
          isLoading={startMutation.isLoading}
        >
          Start
        </LoadingButton>
      </Authorized>
      <Authorized authorizations="AzureContainerGroupAction">
        <LoadingButton
          color="light"
          data-cy="stop-azure-container-button"
          onClick={() =>
            stopMutation.mutate(selectedItems, {
              onSuccess: () => router.stateService.reload(),
            })
          }
          disabled={selectedCount === 0 || !hasRunningItemsSelected}
          icon={Square}
          loadingText="Stopping"
          isLoading={stopMutation.isLoading}
        >
          Stop
        </LoadingButton>
      </Authorized>
      <Authorized authorizations="AzureContainerGroupAction">
        <LoadingButton
          color="light"
          data-cy="restart-azure-container-button"
          onClick={() =>
            restartMutation.mutate(selectedItems, {
              onSuccess: () => router.stateService.reload(),
            })
          }
          disabled={selectedCount === 0 || hasStoppedItemsSelected}
          icon={RefreshCw}
          loadingText="Restarting"
          isLoading={restartMutation.isLoading}
        >
          Restart
        </LoadingButton>
      </Authorized>
      <Authorized authorizations="AzureContainerGroupDelete">
        <DeleteButton
          disabled={selectedItems.length === 0}
          data-cy="remove-containers-button"
          isLoading={deleteMutation.isLoading}
          onConfirmed={() =>
            deleteMutation.mutate(selectedItems, {
              onSuccess: () => onDeleteSuccess(),
            })
          }
          confirmMessage="Are you sure you want to delete the selected containers?"
        />
      </Authorized>
    </ButtonGroup>
  );
}
