export function RepositoryShortTag(name, imageId, imageDigest, manifest) {
  this.Name = name;
  this.ImageId = imageId;
  this.ImageDigest = imageDigest;
  this.ManifestV2 = manifest;
}

export function RepositoryAddTagPayload(tag, manifest) {
  this.Tag = tag;
  this.Manifest = manifest;
}
