import { AccessControlFormData } from '@/react/portainer/access-control/types';
import { PortainerResponse } from '@/react/docker/types';

type OS = 'Linux' | 'Windows';

export const subscriptionApiVersion = '2024-03-01';
export const resourceGroupApiVersion = '2024-03-01';
export const virtualNetworkApiVersion = '2023-11-01';
export const containerInstanceApiVersion = '2024-03-01';
export const containerGroupApiVersion = '2023-05-01';

export interface ContainerInstanceFormValues {
  name: string;
  location?: string;
  subscription?: string;
  resourceGroup?: string;
  restartPolicy: 'Always' | 'OnFailure' | 'Never';
  image: string;
  os: OS;
  memory: number;
  cpu: number;
  enableGPU: boolean;
  gpu: GpuResource;
  virtualNetwork?: string;
  subnet?: string;
  ports: PortMapping[];
  tags: Tag[];
  enableVolumes: boolean;
  volumes: VolumeFormData[];
  allocatePublicIP: boolean;
  accessControl: AccessControlFormData;
}

export type Tag = {
  name: string;
  value: string;
};

export type ContainerGroup = PortainerResponse<{
  id?: string;
  name: string;
  location: string;
  type?: string;
  properties?: ContainerGroupProperties;
  tags?: Record<string, string>;
}>;

export interface ContainerGroupRow extends ContainerGroup {
  resourceGroup?: string;
  resourceGroupId?: string;
}

export interface VolumeFormData {
  shareName?: string;
  storageAccountName?: string;
  storageAccountKey?: string;
  mountPath?: string;
}

interface ContainerGroupProperties {
  containers: (Container | undefined)[];
  instanceView?: {
    events?: ContainerEvent[];
    state?: ProvisioningState;
  };
  ipAddress?: {
    dnsNameLabelReusePolicy?: string;
    ports: PortMapping[];
    type: 'Public' | 'Private';
    ip?: string;
  };
  volumes?: FileShareVolume[];
  osType: OS;
  subnetIds?: {
    id: string;
  }[];
  provisioningState?: ProvisioningState;
}

// https://learn.microsoft.com/en-us/azure/container-instances/container-state#container-groups
export type ProvisioningState =
  | 'Succeeded'
  | 'Pending'
  | 'Created'
  | 'Creating'
  | 'Updating'
  | 'Running'
  | 'Stopped'
  | 'Failed'
  | 'Unknown';

export interface PortMapping {
  port?: number;
  protocol: Protocol;
}

export type Protocol = 'TCP' | 'UDP';

interface Container {
  name: string;
  properties: {
    environmentVariables?: unknown[];
    image: string;
    ports: PortMapping[];
    resources: {
      requests: {
        cpu: number;
        memoryInGB: number;
        gpu?: {
          sku: string;
          count: number;
        };
      };
    };
    instanceView?: {
      events?: ContainerEvent[];
    };
    volumeMounts?: VolumeMount[];
  };
}

export type ContainerEventType = 'Normal' | 'Warning';

export interface ContainerEvent {
  count: number;
  firstTimestamp: string;
  lastTimestamp: string;
  name: string;
  message: string;
  type: ContainerEventType;
}

export interface VolumeMount {
  name: string;
  mountPath: string;
  readOnly?: boolean;
}

export interface FileShareVolume {
  name: string;
  azureFile: {
    sharename: string;
    storageAccountName: string;
    storageAccountKey: string;
  };
}

export interface Subscription {
  subscriptionId: string;
  displayName: string;
}

export interface ResourceGroup {
  id: string;
  name: string;
  location: string;
  subscriptionId: string;
}

export interface ProviderViewModel {
  id: string;
  namespace: string;
  locations: string[];
}

export interface GpuResource {
  count: number;
  sku: 'K80' | 'P100' | 'V100' | 'M60';
}
export interface VirtualNetwork {
  id: string;
  name: string;
  type: string;
  location: string;
  properties: VirtualNetworkProperties;
}
export interface VirtualNetworksResponse {
  value: VirtualNetwork[];
}

interface VirtualNetworkProperties {
  subnets: Subnet[];
}

export interface Subnet {
  id: string;
  name: string;
  properties: SubnetProperties;
}

interface SubnetProperties {
  addressPrefixes: string[];
  delegations?: SubnetDelegation[];
  ipConfigurations?: unknown[];
}

export interface SubnetDelegation {
  id: string;
  name: string;
  type: string;
  properties: SubnetDelegationProperties;
}

interface SubnetDelegationProperties {
  actions: string[];
  serviceName: string;
}

export interface Volume {
  name: string;
  azureFile?: AzureFileVolume;
  emptyDir?: unknown;
  secret?: { [key: string]: string };
  gitRepo?: GitRepoVolume;
}

export interface AzureFileVolume {
  shareName: string;
  readOnly?: boolean;
  storageAccountName: string;
  storageAccountKey: string;
}

export interface GitRepoVolume {
  repository: string;
  directory: string;
  revision?: string;
}

export interface ContainerProbe {
  httpGet?: HttpGetProbe;
  exec?: ExecProbe;
  initialDelaySeconds?: number;
  periodSeconds?: number;
  failureThreshold?: number;
}

export interface HttpGetProbe {
  path: string;
  port: number;
  scheme: 'HTTP' | 'HTTPS';
}

export interface ExecProbe {
  command: string[];
}
