import { useMemo } from 'react';

import {
  ContainerGroup,
  ContainerGroupRow,
  ProvisioningState,
  ResourceGroup,
} from '../types';
import { parseContainerGroupId } from '../utils';

/**
 * Append the resource group name to the container group data
 */
export function useContainerGroupRows(
  containerGroups: ContainerGroup[],
  resourceGroups: ResourceGroup[],
  containerGroupsStatus: (ProvisioningState | undefined)[]
): ContainerGroupRow[] {
  return useMemo(
    () =>
      containerGroups.map((containerGroup, index) => {
        const { resourceGroupName: resourceGroupId } = parseContainerGroupId(
          containerGroup.id ?? '-'
        );
        const resourceGroup = resourceGroups.find(
          (rg) => rg.name === resourceGroupId
        );
        const containerGroupWithState = containerGroup;
        if (containerGroupWithState.properties) {
          containerGroupWithState.properties.instanceView = {
            state: containerGroupsStatus[index],
          };
        }
        return {
          ...containerGroupWithState,
          resourceGroup: resourceGroup?.name,
          resourceGroupId: resourceGroup?.id,
        };
      }),
    [containerGroups, resourceGroups, containerGroupsStatus]
  );
}
