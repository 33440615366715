import _ from 'lodash';

import { ManifestV2 } from '../../queries/manifest.service';
import { ImageConfigs } from '../../queries/getRegistryBlobs';

import { RepositoryTagViewModel } from './view-model';

// convert image configs blob history to manifest v1 history
function parseImageConfigsHistory(imageConfigs: ImageConfigs, v2: ManifestV2) {
  return _.map(imageConfigs.history.reverse(), (item) => ({
    ...item,
    CreatedBy: item.created_by,
    // below fields exist in manifest v1 history but not image configs blob
    id: v2.config.digest,
    created: imageConfigs.created,
    docker_version: imageConfigs.docker_version,
    os: imageConfigs.os,
    architecture: imageConfigs.architecture,
    config: imageConfigs.config,
    container_config: imageConfigs.container_config,
  }));
}

export function manifestsToTag({
  v2,
  imageConfigs,
}: {
  v2: ManifestV2 & { digest: string };
  imageConfigs?: ImageConfigs;
}) {
  let history: unknown[] = [];
  const name = '';
  let os = '';
  let arch = '';

  if (imageConfigs) {
    os = imageConfigs.os || '';
    arch = imageConfigs.architecture || '';
    history = parseImageConfigsHistory(imageConfigs, v2);
  }

  const size = v2.layers.reduce((size, b) => size + b.size, 0);

  const imageId = v2.config.digest;

  // v2.digest comes from
  //  1. Docker-Content-Digest header from the v2 response, or
  //  2. Calculated locally by sha256(v2-response-body)
  const imageDigest = v2.digest;

  return new RepositoryTagViewModel(
    name,
    os,
    arch,
    size,
    imageDigest,
    imageId,
    v2,
    history
  );
}
