import { useField } from 'formik';

import { FormControl } from '@@/form-components/FormControl';
import { FormSection } from '@@/form-components/FormSection';
import { SwitchField } from '@@/form-components/SwitchField';
import { PortainerSelect } from '@@/form-components/PortainerSelect';

const options = [
  {
    label: '1 day',
    value: '24h',
  },
  {
    label: '7 days',
    value: `${24 * 7}h`,
  },
  {
    label: '30 days',
    value: `${24 * 30}h`,
  },
  {
    label: '1 year',
    value: `${24 * 30 * 12}h`,
  },
  {
    label: 'No expiry',
    value: '0',
  },
] as const;

export function KubeConfigSection() {
  const [
    { value: expiryValue },
    { error: expiryError },
    { setValue: setExpiryValue },
  ] = useField<string>('kubeconfigExpiry');
  const [
    { value: disableDownloadValue },
    ,
    { setValue: setDisableDownloadValue },
  ] = useField<boolean>('disableKubeconfigDownload');

  return (
    <FormSection title="Kubeconfig">
      <FormControl label="Kubeconfig expiry" errors={expiryError}>
        <PortainerSelect
          value={expiryValue}
          options={options}
          onChange={(value) => value && setExpiryValue(value)}
          data-cy="kubeconfig-expiry-selector"
        />
      </FormControl>
      <div className="form-group">
        <div className="col-sm-12">
          <SwitchField
            label="Disable Kubeconfig download for non-admin users"
            data-cy="kube-settings-disable-kubeconfig-download"
            checked={disableDownloadValue}
            name="disableKubeconfigDownload"
            onChange={(value) => setDisableDownloadValue(value)}
            labelClass="col-sm-3 col-lg-2"
            tooltip="Prevents users without an environment admin role from downloading the Kubeconfig file"
          />
        </div>
      </div>
    </FormSection>
  );
}
