import { hasAuthorizations, useCurrentUser } from '@/react/hooks/useUser';
import { Environment } from '@/react/portainer/environments/types';

export function useEnvironmentsAuthorizations(
  authorizations: string | string[],
  environments: Environment[]
) {
  const { user, isPureAdmin } = useCurrentUser();
  if (isPureAdmin) {
    return true;
  }

  for (let i = 0; i < environments.length; i++) {
    const authorized = hasAuthorizations(
      user,
      authorizations,
      environments[i].Id
    );
    if (authorized) {
      return true;
    }
  }

  return false;
}

export default useEnvironmentsAuthorizations;
