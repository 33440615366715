import { array, mixed, number, object, SchemaOf } from 'yup';

import { buildUniquenessTest } from '@@/form-components/validate-unique';

import { PortMapping } from '../../types';

export function validationSchema(): SchemaOf<PortMapping[]> {
  return array(
    object().shape({
      port: number().required('Port is required'),
      protocol: mixed().oneOf(['TCP', 'UDP']),
    })
  )
    .test(
      'unique',
      'Ports must be unique',
      buildUniquenessTest(() => 'Ports must be unique', 'port')
    )
    .min(1, 'At least one port is required');
}
