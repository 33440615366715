import { EnvironmentId } from '@/react/portainer/environments/types';
import {
  ContainerInstanceFormValues,
  ProviderViewModel,
  ResourceGroup,
  Subscription,
  VirtualNetwork,
} from '@/react/azure/types';
import { parseAccessControlFormData } from '@/react/portainer/access-control/utils';
import { useCurrentUser } from '@/react/hooks/useUser';
import { useProviders } from '@/react/azure/queries/useProvider';
import { useResourceGroups } from '@/react/azure/queries/useResourceGroups';
import { useSubscriptions } from '@/react/azure/queries/useSubscriptions';

import {
  useSubscriptionLocations,
  useSubscriptionResourceGroups,
} from './utils';

export function useLoadFormState(environmentId: EnvironmentId) {
  const subscriptionsQuery = useSubscriptions(environmentId);
  const subscriptions = subscriptionsQuery.data ?? [];
  const resourceGroupsQuery = useResourceGroups(environmentId, subscriptions);
  const resourceGroups = resourceGroupsQuery.data;
  const providersQuery = useProviders(environmentId, subscriptions);
  const providers = providersQuery.data;

  const isLoading =
    subscriptionsQuery.isLoading ||
    resourceGroupsQuery.isLoading ||
    providersQuery.isLoading;

  return {
    isLoading,
    subscriptions,
    resourceGroups,
    providers,
    isSubscriptionsLoading: subscriptionsQuery.isLoading,
    isResourceGroupsLoading: resourceGroupsQuery.isLoading,
    isProvidersLoading: providersQuery.isLoading,
  };
}

export function useFormState(
  subscriptions: Subscription[] = [],
  resourceGroups: Record<string, ResourceGroup[]> = {},
  providers: Record<string, ProviderViewModel> = {},
  virtualNetworks: VirtualNetwork[] = []
) {
  const { user, isPureAdmin } = useCurrentUser();

  const subscriptionOptions = subscriptions.map((s) => ({
    value: s.subscriptionId,
    label: s.displayName,
  }));

  const initSubscriptionId = getFirstValue(subscriptionOptions);

  const resourceGroupOptions = useSubscriptionResourceGroups(
    initSubscriptionId,
    resourceGroups
  );

  const locationOptions = useSubscriptionLocations(
    initSubscriptionId,
    providers,
    virtualNetworks
  );

  const initialValues: ContainerInstanceFormValues = {
    name: '',
    location: getFirstValue(locationOptions),
    subscription: initSubscriptionId,
    restartPolicy: 'Always',
    resourceGroup: getFirstValue(resourceGroupOptions),
    image: '',
    enableGPU: false,
    gpu: { count: 1, sku: 'V100' },
    os: 'Linux',
    memory: 1,
    cpu: 1,
    ports: [{ port: 80, protocol: 'TCP' }],
    allocatePublicIP: true,
    accessControl: parseAccessControlFormData(isPureAdmin, user.Id),
    tags: [],
    enableVolumes: false,
    volumes: [
      // initialise volumes with empty strings to avoid controlled/uncontrolled input warning
      {
        shareName: '',
        storageAccountName: '',
        storageAccountKey: '',
        mountPath: '',
      },
    ],
  };

  return {
    initialValues,
    resourceGroupOptions,
    subscriptionOptions,
    locationOptions,
  };

  function getFirstValue<T>(arr: { value: T }[]) {
    if (arr.length === 0) {
      return undefined;
    }

    return arr[0].value;
  }
}
