import { array, object, SchemaOf, string } from 'yup';

import { VolumeFormData } from '../../types';

export function getVolumesValidation(): SchemaOf<VolumeFormData[]> {
  return array(volumesOptional()).when('enableVolumes', {
    is: true,
    then: array(volumesRequired()),
  });
}

function volumesRequired(): SchemaOf<VolumeFormData> {
  return object().shape({
    shareName: string().required('Volume name is required.'),
    storageAccountName: string().required('Storage account name is required.'),
    storageAccountKey: string().required('Storage account key is required.'),
    mountPath: string().required('Mount path is required.'),
  });
}

function volumesOptional(): SchemaOf<VolumeFormData> {
  return object().shape({
    shareName: string(),
    storageAccountName: string(),
    storageAccountKey: string(),
    mountPath: string(),
  });
}
