import { useCurrentStateAndParams } from '@uirouter/react';
import { useMemo } from 'react';

import { useEnvironmentId } from '@/react/hooks/useEnvironmentId';
import { ResourceControlViewModel } from '@/react/portainer/access-control/models/ResourceControlViewModel';
import { ContainerGroup, Subscription, Tag } from '@/react/azure/types';
import { useContainerGroup } from '@/react/azure/queries/useContainerGroup';
import { useSubscription } from '@/react/azure/queries/useSubscription';
import {
  getPorts,
  parseContainerGroupId,
  statusColor,
} from '@/react/azure/utils';
import { getSchemeFromPort } from '@/react/common/network-utils';

import { Widget, WidgetBody } from '@@/Widget';
import { FormSectionTitle } from '@@/form-components/FormSectionTitle';
import { DetailsTable } from '@@/DetailsTable';
import { Badge } from '@@/Badge';
import { StatusBadge } from '@@/StatusBadge';
import { ExternalLink } from '@@/ExternalLink';
import { InlineLoader } from '@@/InlineLoader';

export function ContainerSummaryWidget() {
  const {
    params: { id },
  } = useCurrentStateAndParams();
  const { subscriptionId, resourceGroupName, containerGroupId } =
    parseContainerGroupId(id);

  const environmentId = useEnvironmentId();

  const subscriptionQuery = useSubscription(environmentId, subscriptionId);

  const containerQuery = useContainerGroup(
    environmentId,
    subscriptionId,
    resourceGroupName,
    containerGroupId
  );

  const container = useContainerData(
    containerGroupId,
    resourceGroupName,
    subscriptionQuery.data,
    containerQuery.data
  );

  return (
    <Widget>
      <WidgetBody className="form-horizontal">
        <DetailsTable dataCy="azure-summary-table">
          <tr>
            <td className="w-1/3">Name</td>
            <td data-cy="aci-container-name">{container.name || '-'}</td>
          </tr>
          <tr>
            <td>State</td>
            <td data-cy="aci-provisioning-state">
              {containerQuery.isLoading ? (
                <InlineLoader>Loading state</InlineLoader>
              ) : (
                <StatusBadge color={statusColor[container.provisioningState]}>
                  {container.provisioningState}
                </StatusBadge>
              )}
            </td>
          </tr>
          <tr>
            <td>Published ports</td>
            <td data-cy="aci-container-location-input">
              {container.publishedPorts.length >= 1
                ? container.publishedPorts.map((port) => (
                    <ExternalLink key={port} to={port} data-cy={port}>
                      {port}
                    </ExternalLink>
                  ))
                : '-'}
            </td>
          </tr>
          {!!container.subnet && (
            <tr>
              <td>Virtual network</td>
              <td data-cy="aci-virtual-network-input">
                {container.subnet || '-'}
              </td>
            </tr>
          )}
          <tr>
            <td>Tags</td>
            <td
              data-cy="aci-container-tags-input"
              className="flex flex-wrap gap-1"
            >
              {container.tags.length >= 1
                ? container.tags.map((tag: Tag) => (
                    <Badge key={tag.name} type="info">
                      {tag.name}: {tag.value}
                    </Badge>
                  ))
                : '-'}
            </td>
          </tr>
        </DetailsTable>
        <FormSectionTitle>Azure settings</FormSectionTitle>
        <DetailsTable dataCy="azure-settings-table">
          <tr>
            <td className="w-1/3">Subscription</td>
            <td data-cy="aci-container-subscription-input">
              {container.subscriptionName}
            </td>
          </tr>
          <tr>
            <td>Resource group</td>
            <td data-cy="aci-container-resourceGroup-input">
              {container.resourceGroupName}
            </td>
          </tr>
          <tr>
            <td>Location</td>
            <td data-cy="aci-container-location-input">
              {container.location || '-'}
            </td>
          </tr>
        </DetailsTable>

        <FormSectionTitle>Container configuration</FormSectionTitle>
        <DetailsTable dataCy="container-configuration-table">
          <tr>
            <td className="w-1/3">Image</td>
            <td data-cy="aci-container-image-input">
              {container.imageName || '-'}
            </td>
          </tr>
          <tr>
            <td>OS</td>
            <td data-cy="aci-container-os-input">{container.osType || '-'}</td>
          </tr>
          <tr>
            <td>CPU</td>
            <td data-cy="aci-container-cpu-input">
              {container.cpu !== undefined ? container.cpu : '-'}
            </td>
          </tr>
          <tr>
            <td>Memory</td>
            <td data-cy="aci-container-memory-input">
              {container.memory !== undefined ? `${container.memory} GB` : '-'}
            </td>
          </tr>
          {container.gpu !== undefined && (
            <tr>
              <td>GPU</td>
              <td data-cy="aci-container-gpu-input">
                {container.gpu.sku} x {container.gpu.count}
              </td>
            </tr>
          )}
          {container.volumeMounts && container.volumeMounts.length >= 1 && (
            <tr>
              <td>Volume mounts</td>
              <td data-cy="aci-container-volume-mounts-input">
                {container.volumeMounts.map((mount) => (
                  <div key={mount.name}>
                    {mount.name} - <code>{mount.mountPath}</code>
                  </div>
                ))}
              </td>
            </tr>
          )}
        </DetailsTable>
      </WidgetBody>
    </Widget>
  );
}

function useContainerData(
  containerGroupName: string,
  resourceGroupName: string,
  subscription?: Subscription,
  containerGroup?: ContainerGroup
) {
  return useMemo(
    () =>
      aggregateContainerData(
        containerGroupName,
        resourceGroupName,
        subscription,
        containerGroup
      ),
    [containerGroup, containerGroupName, resourceGroupName, subscription]
  );
}

function aggregateContainerData(
  containerGroupName: string,
  resourceGroupName: string,
  subscription?: Subscription,
  containerGroup?: ContainerGroup
) {
  const containerInstanceData = aggregateContainerInstance();
  const resourceControl = containerGroup?.Portainer?.ResourceControl
    ? new ResourceControlViewModel(containerGroup.Portainer.ResourceControl)
    : undefined;

  const tags: Tag[] = Object.entries(containerGroup?.tags || {}).map(
    ([name, value]) => ({
      name,
      value,
    })
  );

  // get the published ports (as URLs)
  const ports = getPorts(containerGroup);
  const ip = containerGroup?.properties?.ipAddress?.ip || '';
  const publishedPorts =
    ip.length >= 1
      ? ports.map((port) => {
          const scheme = getSchemeFromPort(port.host);
          return `${scheme}://${ip}:${port.host}`;
        })
      : [];

  // get the subnet with virtual network name
  const subnetIdPath = containerGroup?.properties?.subnetIds?.[0]?.id || '';
  let subnet;
  if (!subnetIdPath) {
    subnet = '';
  } else {
    const splitSubnetPath = subnetIdPath.split('/');
    const subnetName = splitSubnetPath[splitSubnetPath.length - 1];
    const virtualNetworkName = splitSubnetPath[splitSubnetPath.length - 3];
    subnet = `${virtualNetworkName} - ${subnetName}`;
  }

  return {
    name: containerGroupName,
    subscriptionName: subscription?.displayName,
    resourceGroupName,
    location: containerGroup?.location,
    osType: containerGroup?.properties?.osType,
    ipAddress: containerGroup?.properties?.ipAddress?.ip,
    tags,
    provisioningState:
      containerGroup?.properties?.instanceView?.state || 'Unknown',
    resourceControl,
    publishedPorts,
    subnet,
    ...containerInstanceData,
  };

  function aggregateContainerInstance() {
    const containerInstanceData = containerGroup?.properties?.containers?.[0];

    if (!containerInstanceData) {
      return {
        ports: [],
      };
    }

    const containerInstanceProperties = containerInstanceData.properties;

    const containerPorts = containerInstanceProperties.ports;

    const imageName = containerInstanceProperties.image;

    const ports =
      containerGroup.properties?.ipAddress?.ports.map((binding, index) => {
        const port =
          containerPorts && containerPorts[index]
            ? containerPorts[index].port
            : undefined;
        return {
          container: port,
          host: binding.port,
          protocol: binding.protocol,
        };
      }) ?? [];

    const { volumeMounts } = containerInstanceProperties;

    return {
      imageName,
      ports,
      cpu: containerInstanceProperties.resources.requests.cpu,
      memory: containerInstanceProperties.resources.requests.memoryInGB,
      gpu: containerInstanceProperties.resources.requests.gpu,
      volumeMounts,
    };
  }
}
