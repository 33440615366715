import { Puzzle } from 'lucide-react';

import { notifySuccess } from '@/portainer/services/notifications';
import { withLimitToBE } from '@/react/hooks/useLimitToBE';
import { capitalize } from '@/react/common/string-utils';

import { Datatable } from '@@/datatables';
import { PageHeader } from '@@/PageHeader';
import { AddButton } from '@@/buttons';
import { useTableState } from '@@/datatables/useTableState';
import { Tab, WidgetTabs, useCurrentTabIndex } from '@@/Widget/WidgetTabs';
import { DeleteButton } from '@@/buttons/DeleteButton';

import { useList } from '../queries/list/list';
import { EdgeConfiguration, EdgeConfigurationCategory } from '../types';
import { useRemoveMutation } from '../queries/useRemoveMutation';

import { columns } from './columns';
import { createStore } from './datatable-store';

const storageKey = 'edge-configurations-list';
const settingsStore = createStore(storageKey);

export default withLimitToBE(ListView);

const tabs: Tab[] = [
  {
    name: 'Configurations',
    widget: <div />,
    selectedTabParam: 'configurations',
  },
  // {
  //   name: 'Secrets',
  //   widget: <div />,
  //   selectedTabParam: 'secrets',
  // },
];

const categories = [
  EdgeConfigurationCategory.Configuration,
  EdgeConfigurationCategory.Secret,
];

export function ListView() {
  const tableState = useTableState(settingsStore, storageKey);

  const [currentTabIndex] = useCurrentTabIndex(tabs);

  const title = `${tabs[currentTabIndex].name} list`;

  const category = categories[currentTabIndex];

  const { data: configurations, isLoading } = useList({
    category,
  });

  if (!configurations) {
    return null;
  }

  return (
    <>
      <PageHeader
        title="Edge configurations"
        breadcrumbs="Edge configurations"
        reload
      />

      <WidgetTabs tabs={tabs} currentTabIndex={currentTabIndex} />

      <Datatable
        dataset={configurations}
        columns={columns}
        settingsManager={tableState}
        title={title}
        titleIcon={Puzzle}
        renderTableActions={(selectedRows) => (
          <TableActions selectedRows={selectedRows} category={category} />
        )}
        isLoading={isLoading}
        data-cy="edge-configurations-datatable"
      />
    </>
  );
}

function TableActions({
  selectedRows,
  category,
}: {
  selectedRows: EdgeConfiguration[];
  category: EdgeConfigurationCategory;
}) {
  const removeMutation = useRemoveMutation();
  return (
    <>
      <DeleteButton
        onConfirmed={() => handleRemove()}
        disabled={selectedRows.length === 0}
        data-cy="remove-edge-configurations-button"
        confirmMessage={`Do you want to remove the selected ${category}(s) from all devices within the edge groups corresponding to these ${category}(s)?`}
      />

      <AddButton
        to=".create"
        params={{ category }}
        data-cy="add-edge-configuration-buton"
      >
        Add {category}
      </AddButton>
    </>
  );

  async function handleRemove() {
    removeMutation.mutate(selectedRows, {
      onSuccess: () => {
        notifySuccess(
          'Success',
          `${capitalize(category)}s successfully removed`
        );
      },
    });
  }
}
