import { useQuery } from '@tanstack/react-query';

import axios from '@/portainer/services/axios';
import { EnvironmentId } from '@/react/portainer/environments/types';
import { withError } from '@/react-tools/react-query';

import { Subscription, subscriptionApiVersion } from '../types';

import { buildSubscriptionsUrl, parseAzureAxiosError } from './utils';
import { queryKeys } from './query-keys';

export function useSubscriptions(environmentId: EnvironmentId) {
  return useQuery(
    queryKeys.subscriptions(environmentId),
    () => getSubscriptions(environmentId),
    {
      ...withError('Unable to retrieve Azure subscriptions'),
    }
  );
}

async function getSubscriptions(environmentId: EnvironmentId) {
  try {
    const { data } = await axios.get<{ value: Subscription[] }>(
      buildSubscriptionsUrl(environmentId),
      { params: { 'api-version': subscriptionApiVersion } }
    );
    return data.value;
  } catch (e) {
    throw parseAzureAxiosError(e, 'Unable to retrieve subscriptions');
  }
}
