import { createColumnHelper } from '@tanstack/react-table';

import { ContainerEvent, ContainerEventType } from '@/react/azure/types';
import { formatDate } from '@/portainer/filters/filters';

import { Badge, BadgeType } from '@@/Badge';
import { Tooltip } from '@@/Tip/Tooltip';

export const columnHelper = createColumnHelper<ContainerEvent>();

const eventTypeBadgeTypeMap: Record<ContainerEventType, BadgeType> = {
  Normal: 'info',
  Warning: 'warn',
};

export const columns = [
  columnHelper.accessor((event) => formatDate(event.lastTimestamp), {
    header: 'Date',
    id: 'lastTimestamp',
  }),
  columnHelper.accessor('type', {
    header: 'Type',
    cell: ({ getValue }) => {
      const type = getValue();
      return <Badge type={eventTypeBadgeTypeMap[type]}>{type}</Badge>;
    },
  }),
  columnHelper.accessor('message', {
    header: 'Message',
    cell: ({ getValue }) => (
      <div className="whitespace-pre-wrap">{getValue()}</div>
    ),
  }),
  columnHelper.accessor('count', {
    header: () => (
      <>
        Count
        <Tooltip message="The number of times the event has fired." />
      </>
    ),
  }),
];
