import { useRouter } from '@uirouter/react';

import * as notifications from '@/portainer/services/notifications';
import type { EnvironmentId } from '@/react/portainer/environments/types';
import { VolumeViewModel } from '@/docker/models/volume';

import { ButtonGroup } from '@@/buttons';
import { DeleteButton } from '@@/buttons/DeleteButton';

import { removeVolume } from './volumes.service';

interface Props {
  selectedItems: VolumeViewModel[];
  endpointId: EnvironmentId;
}

export function VolumesDatatableActions({ selectedItems, endpointId }: Props) {
  const selectedItemCount = selectedItems.length;

  const router = useRouter();

  return (
    <ButtonGroup>
      <DeleteButton
        confirmMessage="Are you sure you want to remove the selected volumes?"
        onConfirmed={() => onRemoveClick(selectedItems)}
        disabled={selectedItemCount === 0}
        data-cy="edge-devices-remove-volubutton"
      />
    </ButtonGroup>
  );

  async function onRemoveClick(selectedItems: VolumeViewModel[]) {
    const volumes = selectedItems;

    for (let i = 0; i < volumes.length; i += 1) {
      const volume = volumes[i];
      try {
        await removeVolume(endpointId, volume.Id);
        notifications.success('Volume removal successfully planned', volume.Id);
      } catch (err) {
        notifications.error(
          'Failure',
          err as Error,
          'Unable to schedule volume removal'
        );
      }
    }

    router.stateService.reload();
  }
}
