import { useMemo } from 'react';

import { ContainerGroup } from '../../types';

export function getAllEvents(container?: ContainerGroup) {
  if (!container) {
    return [];
  }

  const containerEvents =
    container.properties?.containers?.[0]?.properties?.instanceView?.events ??
    [];

  const containerGroupEvents = container.properties?.instanceView?.events ?? [];

  return [...containerEvents, ...containerGroupEvents];
}

export function useEventWarningCount(container?: ContainerGroup) {
  return useMemo(() => {
    const allEvents = getAllEvents(container);
    const warningEvents = allEvents.filter((event) => event.type === 'Warning');
    return warningEvents.length;
  }, [container]);
}
