// POST https://management.azure.com/subscriptions/{subscriptionId}/resourceGroups/{resourceGroupName}/providers/Microsoft.ContainerInstance/containerGroups/{containerGroupName}/restart?api-version=2023-05-01
import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAllSettledItems } from '@/portainer/helpers/promise-utils';
import { notifyError, notifySuccess } from '@/portainer/services/notifications';
import { EnvironmentId } from '@/react/portainer/environments/types';
import { withError } from '@/react-tools/react-query';
import axios from '@/portainer/services/axios';
import { pluralize } from '@/portainer/helpers/strings';

import { ContainerGroup, containerGroupApiVersion } from '../types';

import { queryKeys } from './query-keys';
import { parseAzureAxiosError } from './utils';

type Action = 'start' | 'stop' | 'restart';

const actionVerbMap: Record<Action, string> = {
  start: 'started',
  stop: 'stopped',
  restart: 'restarted',
};

export function useContainerGroupsAction(
  environmentId: EnvironmentId,
  action: Action
) {
  const queryClient = useQueryClient();
  return useMutation(
    (containerGroups: ContainerGroup[]) =>
      postContainerGroupsAction(environmentId, containerGroups, action),
    {
      onSuccess({ fulfilledItems, rejectedItems }) {
        // one error notification per rejected item
        rejectedItems.forEach(({ item, reason }) => {
          notifyError(
            `Failed to ${action} container '${item.name}'`,
            new Error(reason)
          );
        });

        // one success notification for all fulfilled items
        if (fulfilledItems.length) {
          notifySuccess(
            `${pluralize(
              fulfilledItems.length,
              'Container group'
            )} successfully ${actionVerbMap[action]}`,
            fulfilledItems.map((item) => item.name).join(', ')
          );
        }

        return queryClient.invalidateQueries(
          queryKeys.subscriptions(environmentId)
        );
      },
      ...withError(`Unable to ${action} container groups`),
    }
  );
}

async function postContainerGroupsAction(
  environmentId: EnvironmentId,
  containerGroups: ContainerGroup[],
  action: Action
) {
  return getAllSettledItems(containerGroups, postContainerGroupAction);

  async function postContainerGroupAction(containerGroup: ContainerGroup) {
    const containerGroupId = containerGroup.id;
    try {
      await axios.post(
        `/endpoints/${environmentId}/azure${containerGroupId}/${action}`,
        undefined,
        {
          params: { 'api-version': containerGroupApiVersion },
        }
      );
    } catch (e) {
      throw parseAzureAxiosError(e, `Unable to ${action} container group`);
    }
  }
}
