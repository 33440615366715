import { Secret } from 'kubernetes-types/core/v1';
import { LucideIcon, Plus } from 'lucide-react';

import { Icon } from '@@/Icon';
import { Link } from '@@/Link';

import { useIsSecretRestricted } from '../../configs/useIsSecretRestricted';
import { RestrictedSecretBadge } from '../../configs/RestrictedSecretBadge';

export function SecretLink({
  name = '',
  namespace,
  secret,
  icon = Plus,
}: {
  name?: string;
  namespace: string;
  secret: Secret;
  icon?: LucideIcon;
}) {
  const isSecretRestricted = useIsSecretRestricted(secret);

  if (isSecretRestricted) {
    return (
      <span className="flex items-center gap-x-1">
        <Icon icon={icon} />
        {name}
        <RestrictedSecretBadge />
      </span>
    );
  }

  return (
    <Link
      className="flex items-center"
      to="kubernetes.secrets.secret"
      params={{ name, namespace }}
      data-cy={`secret-link-${name}`}
    >
      <Icon icon={icon} className="!mr-1" />
      {name}
    </Link>
  );
}
