import angular from 'angular';

import { r2a } from '@/react-tools/react2angular';
import { withUIRouter } from '@/react-tools/withUIRouter';
import { withReactQuery } from '@/react-tools/withReactQuery';
import { LicensesDatatable } from '@/react/portainer/licenses/ListView/LicensesDatatable/LicensesDatatable';

export const licensesModule = angular
  .module('portainer.app.react.components.licenses', [])
  .component(
    'licensesDatatable',
    r2a(withUIRouter(withReactQuery(LicensesDatatable)), [
      'dataset',
      'onRemove',
    ])
  ).name;
