import { Field, Form, Formik, FormikProps } from 'formik';
import { useRouter } from '@uirouter/react';

import { ContainerInstanceFormValues } from '@/react/azure/types';
import { useCurrentUser } from '@/react/hooks/useUser';
import { AccessControlForm } from '@/react/portainer/access-control/AccessControlForm';
import { useEnvironmentId } from '@/react/hooks/useEnvironmentId';
import { notifyError, notifySuccess } from '@/portainer/services/notifications';

import { FormControl } from '@@/form-components/FormControl';
import { Input } from '@@/form-components/Input';
import { FormSectionTitle } from '@@/form-components/FormSectionTitle';
import { PortainerSelect, Option } from '@@/form-components/PortainerSelect';
import { FormActions } from '@@/form-components/FormActions';

import { useSubscriptions } from '../../queries/useSubscriptions';
import { useResourceGroups } from '../../queries/useResourceGroups';
import { useProviders } from '../../queries/useProvider';
import { useVirtualNetworks } from '../../queries/useVirtualNetworks';
import { useContainerGroups } from '../../queries/useContainerGroups';
import { useContainerGroupRows } from '../useContainerGroupRows';

import { validationSchema } from './CreateContainerInstanceForm.validation';
import { useFormState } from './useLoadFormState';
import { useCreateContainerInstance } from './useCreateContainerInstance';
import { NetworksFormSection } from './NetworksFormSection';
import { TagsField } from './TagsField';
import { useLocationOptionsWithVNs } from './utils';
import { GPUFormSection } from './GPUFormSection';
import { VolumeFormSection } from './VolumeFormSection';

export function CreateContainerInstanceForm() {
  const environmentId = useEnvironmentId();
  const { isPureAdmin } = useCurrentUser();

  // querys to build form state
  const subscriptionsQuery = useSubscriptions(environmentId);
  const subscriptions = subscriptionsQuery.data ?? [];
  const resourceGroupsQuery = useResourceGroups(environmentId, subscriptions);
  const resourceGroups = resourceGroupsQuery.data;
  const providersQuery = useProviders(environmentId, subscriptions);
  const providers = providersQuery.data;
  const virtualNetworksQuery = useVirtualNetworks(
    environmentId,
    subscriptions?.[0]?.subscriptionId || ''
  );
  const virtualNetworks = virtualNetworksQuery.data || [];

  const containerGroupsQuery = useContainerGroups(
    environmentId,
    subscriptionsQuery.data,
    subscriptionsQuery.isSuccess
  );
  const allResourceGroups = Object.values(resourceGroupsQuery.data).flat();
  const containerGroupRows = useContainerGroupRows(
    containerGroupsQuery.data,
    allResourceGroups,
    []
  );

  const {
    initialValues,
    subscriptionOptions,
    locationOptions,
    resourceGroupOptions,
  } = useFormState(subscriptions, resourceGroups, providers, virtualNetworks);

  const router = useRouter();

  const { mutateAsync } = useCreateContainerInstance(
    resourceGroups,
    environmentId
  );

  return (
    <Formik<ContainerInstanceFormValues>
      initialValues={initialValues}
      validationSchema={() => validationSchema(isPureAdmin, containerGroupRows)}
      onSubmit={onSubmit}
      validateOnMount
      validateOnChange
      enableReinitialize
    >
      {(formikProps) => (
        <InnerForm
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...formikProps}
          resourceGroupOptions={resourceGroupOptions}
          subscriptionOptions={subscriptionOptions}
          locationOptions={locationOptions}
          isSubscriptionsLoading={subscriptionsQuery.isLoading}
          isResourceGroupsLoading={resourceGroupsQuery.isLoading}
          isProvidersLoading={providersQuery.isLoading}
          environmentId={environmentId}
        />
      )}
    </Formik>
  );

  async function onSubmit(values: ContainerInstanceFormValues) {
    // find the subnet
    const isUsingSubnet = !values.allocatePublicIP && !!values.subnet;
    const vnWithSubnet = isUsingSubnet
      ? virtualNetworks.find((vn) => vn.id === values.virtualNetwork)
      : undefined;
    const subnet = vnWithSubnet?.properties.subnets.find(
      (sn) => sn.id === values.subnet
    );
    try {
      await mutateAsync({ values, subnet });
      notifySuccess('Container successfully created', values.name);
      router.stateService.go('azure.containerinstances');
    } catch (e) {
      notifyError('Failure', e as Error, 'Unable to create container');
    }
  }
}

type Props = FormikProps<ContainerInstanceFormValues> & {
  resourceGroupOptions: Option<string>[];
  subscriptionOptions: Option<string>[];
  environmentId: number;
  locationOptions: Option<string>[];
  isSubscriptionsLoading: boolean;
  isResourceGroupsLoading: boolean;
  isProvidersLoading: boolean;
};

function InnerForm({
  errors,
  handleSubmit,
  isSubmitting,
  isValid,
  values,
  setFieldValue,
  environmentId,
  resourceGroupOptions,
  subscriptionOptions,
  locationOptions,
  isSubscriptionsLoading,
  isResourceGroupsLoading,
  isProvidersLoading,
}: Props) {
  // repeat the useVirtualNetworks query, because the selected subscription may change
  const virtualNetworksQuery = useVirtualNetworks(
    environmentId,
    values.subscription || ''
  );
  const virtualNetworks = virtualNetworksQuery.data || [];
  const updatedLocationOptions = useLocationOptionsWithVNs(
    locationOptions,
    virtualNetworks
  );
  return (
    <Form className="form-horizontal" onSubmit={handleSubmit} noValidate>
      <FormSectionTitle>Azure Settings</FormSectionTitle>
      <FormControl
        label="Subscription"
        inputId="subscription-input"
        isLoading={isSubscriptionsLoading}
        loadingText="Loading subscriptions..."
        errors={errors.subscription}
      >
        <Field
          name="subscription"
          as={PortainerSelect}
          onChange={(selectedOption: string) =>
            setFieldValue('subscription', selectedOption)
          }
          id="subscription-input"
          options={subscriptionOptions}
          data-cy="subscription-select"
        />
      </FormControl>
      <FormControl
        label="Resource Group"
        inputId="resourceGroup-input"
        isLoading={isResourceGroupsLoading}
        loadingText="Loading resource groups..."
        errors={errors.resourceGroup}
      >
        <Field
          name="resourceGroup"
          as={PortainerSelect}
          onChange={(selectedOption: string) =>
            setFieldValue('resourceGroup', selectedOption)
          }
          id="resourceGroup-input"
          options={resourceGroupOptions}
          data-cy="resource-group-select"
        />
      </FormControl>
      <FormControl
        label="Location"
        inputId="location-input"
        isLoading={isProvidersLoading || virtualNetworksQuery.isLoading}
        loadingText="Loading locations..."
        errors={errors.location}
      >
        <Field
          name="location"
          as={PortainerSelect}
          onChange={(selectedOption: string) =>
            setFieldValue('location', selectedOption)
          }
          id="location-input"
          options={updatedLocationOptions}
          data-cy="location-select"
        />
      </FormControl>
      <FormSectionTitle>Container Configuration</FormSectionTitle>
      <FormControl
        label="Name"
        inputId="name-input"
        errors={errors.name}
        required
      >
        <Field
          name="name"
          as={Input}
          id="name-input"
          placeholder="e.g. myContainer"
          data-cy="name-input"
        />
      </FormControl>
      <FormControl
        label="Image"
        inputId="image-input"
        errors={errors.image}
        required
      >
        <Field
          name="image"
          as={Input}
          id="image-input"
          placeholder="e.g. nginx:alpine"
          data-cy="image-input"
        />
      </FormControl>
      <FormControl label="OS" inputId="os-input" errors={errors.os}>
        <Field
          name="os"
          as={PortainerSelect}
          id="os-input"
          options={[
            { label: 'Linux', value: 'Linux' },
            { label: 'Windows', value: 'Windows' },
          ]}
          onChange={(selectedOption: string) =>
            setFieldValue('os', selectedOption)
          }
          data-cy="os-select"
        />
      </FormControl>
      <TagsField />
      <NetworksFormSection
        environmentId={environmentId}
        locationOptions={locationOptions}
      />
      <FormSectionTitle>Container Resources</FormSectionTitle>
      <FormControl label="CPU" inputId="cpu-input" errors={errors.cpu} required>
        <Field
          name="cpu"
          as={Input}
          min={1}
          id="cpu-input"
          type="number"
          placeholder="1"
          data-cy="cpu-input"
        />
      </FormControl>
      <FormControl
        label="Memory (GB)"
        inputId="cpu-input"
        errors={errors.memory}
        required
      >
        <Field
          name="memory"
          as={Input}
          min={1}
          id="memory-input"
          type="number"
          placeholder="1"
          data-cy="memory-input"
        />
      </FormControl>
      <VolumeFormSection />
      <GPUFormSection />
      <AccessControlForm
        formNamespace="accessControl"
        onChange={(values) => setFieldValue('accessControl', values)}
        values={values.accessControl}
        errors={errors.accessControl}
        environmentId={environmentId}
      />
      <FormActions
        submitLabel="Deploy the container"
        loadingText="Deployment in progress..."
        isLoading={isSubmitting}
        isValid={isValid}
        data-cy="aci-create-actions"
      />
    </Form>
  );
}
