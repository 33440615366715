import { Award, Check, X } from 'lucide-react';
import { createColumnHelper } from '@tanstack/react-table';
import _ from 'lodash';
import moment from 'moment';

import { Datatable } from '@@/datatables';
import { createPersistedStore } from '@@/datatables/types';
import { useTableState } from '@@/datatables/useTableState';
import { Icon } from '@@/Icon';
import { AddButton, CopyButton } from '@@/buttons';
import { DeleteButton } from '@@/buttons/DeleteButton';

import { License, LicenseType } from '../../types';

const tableKey = 'licenses-table';

const store = createPersistedStore(tableKey);

const columns = getColumns();

export function LicensesDatatable({
  dataset,
  onRemove,
}: {
  dataset: Array<License> | undefined;
  onRemove: (items: Array<License>) => void;
}) {
  const tableState = useTableState(store, tableKey);

  return (
    <Datatable
      title="Licenses"
      titleIcon={Award}
      dataset={dataset || []}
      columns={columns}
      isLoading={!dataset}
      settingsManager={tableState}
      renderTableActions={(selectedItems) => (
        <>
          <DeleteButton
            disabled={selectedItems.length === 0}
            onConfirmed={() => onRemove(selectedItems)}
            confirmMessage="Are you sure you want to remove these licenses?"
            data-cy="remove-licenses-button"
          />
          <AddButton data-cy="add-license-button">Add license</AddButton>
        </>
      )}
      data-cy="licenses-datatable"
    />
  );
}

function getColumns() {
  const columnHelper = createColumnHelper<License>();

  return _.compact([
    columnHelper.accessor('company', {
      header: 'Company',
    }),
    columnHelper.accessor('type', {
      header: 'Type',
      cell: ({ getValue }) => LicenseType[getValue()],
    }),
    columnHelper.accessor('nodes', {
      header: 'Nodes',
      cell: ({ getValue }) => getValue() || '-',
    }),
    columnHelper.accessor(
      (license) => moment.unix(license.expiresAt).format('YYYY-MM-DD HH:mm'),
      {
        header: 'Expires',
      }
    ),
    columnHelper.accessor(
      (license) => {
        const expiresAt = moment.unix(license.expiresAt);
        return !license.revoked && moment().isBefore(expiresAt);
      },
      {
        header: 'Valid',
        cell: ({ getValue }) =>
          getValue() ? (
            <Icon icon={Check} mode="success" size="md" />
          ) : (
            <Icon icon={X} mode="danger" size="md" />
          ),
      }
    ),
    columnHelper.display({
      header: 'Actions',
      cell: ({ row: { original: item } }) => (
        <CopyButton
          copyText={item.licenseKey}
          color="link"
          data-cy="copy-license-key-button"
        />
      ),
    }),
  ]);
}
