import angular from 'angular';

import { r2a } from '@/react-tools/react2angular';
import { SettingsOpenAMT } from '@/react/portainer/settings/EdgeComputeView/SettingsOpenAMT';
import { InternalAuth } from '@/react/portainer/settings/AuthenticationView/InternalAuth';
import { LDAPUsersTable } from '@/react/portainer/settings/AuthenticationView/LDAPAuth/LDAPUsersTable';
import { LDAPGroupsTable } from '@/react/portainer/settings/AuthenticationView/LDAPAuth/LDAPGroupsTable';
import { AuthStyleField } from '@/react/portainer/settings/AuthenticationView/OAuth';

export const settingsModule = angular
  .module('portainer.app.react.components.settings', [])
  .component('settingsOpenAmt', r2a(SettingsOpenAMT, ['onSubmit', 'settings']))
  .component(
    'internalAuth',
    r2a(InternalAuth, ['onSaveSettings', 'isLoading', 'value', 'onChange'])
  )
  .component('ldapUsersDatatable', r2a(LDAPUsersTable, ['dataset']))
  .component('ldapGroupsDatatable', r2a(LDAPGroupsTable, ['dataset']))
  .component(
    'oauthAuthStyle',
    r2a(AuthStyleField, [
      'value',
      'onChange',
      'label',
      'tooltip',
      'readonly',
      'size',
    ])
  ).name;
