import { useQuery } from '@tanstack/react-query';

import { EnvironmentId } from '@/react/portainer/environments/types';
import axios from '@/portainer/services/axios';
import { withError } from '@/react-tools/react-query';

import { Subscription, subscriptionApiVersion } from '../types';

import { buildSubscriptionsUrl, parseAzureAxiosError } from './utils';
import { queryKeys } from './query-keys';

export function useSubscription(
  environmentId: EnvironmentId,
  subscriptionId: string
) {
  return useQuery(
    queryKeys.subscription(environmentId, subscriptionId),
    () => getSubscription(environmentId, subscriptionId),
    {
      ...withError('Unable to retrieve Azure subscription'),
    }
  );
}

async function getSubscription(
  environmentId: EnvironmentId,
  subscriptionId: string
) {
  try {
    const { data } = await axios.get<Subscription>(
      buildSubscriptionsUrl(environmentId, subscriptionId),
      { params: { 'api-version': subscriptionApiVersion } }
    );

    return data;
  } catch (e) {
    throw parseAzureAxiosError(e, 'Unable to retrieve subscription');
  }
}
