import { object, string, number, boolean, SchemaOf, mixed, array } from 'yup';

import { validationSchema as accessControlSchema } from '@/react/portainer/access-control/AccessControlForm/AccessControlForm.validation';

import { ContainerGroupRow, ContainerInstanceFormValues } from '../../types';

import { validationSchema as portsSchema } from './PortsMappingField.validation';
import { getVolumesValidation } from './VolumeFormSection.validation';

// matches the regex in azure portal
export const CONTAINER_NAME_VALIDATION_REGEX =
  /^(([a-z0-9](?:(?:[-a-z0-9_.]){0,61}[a-z0-9])?))$/;

export function validationSchema(
  isAdmin: boolean,
  containerGroups: ContainerGroupRow[]
): SchemaOf<ContainerInstanceFormValues> {
  return object().shape({
    name: string()
      .required('Name is required.')
      .matches(
        CONTAINER_NAME_VALIDATION_REGEX,
        'Name must consist of lower case alphanumeric characters, "-", "_" or ".", must start and end with an alphanumeric character and must be 63 characters or less (e.g. "my-name", or "abc-123").'
      )
      .test(
        'unique-name',
        'Container name must be unique.',
        (name, context) => {
          const selectedResourceGroupId = context.parent
            .resourceGroup as ContainerInstanceFormValues['resourceGroup'];
          const hasDuplicateNameInRG = containerGroups.some(
            (group) =>
              group.name === name &&
              group.resourceGroupId === selectedResourceGroupId
          );
          return !hasDuplicateNameInRG;
        }
      ),
    image: string().required('Image is required.'),
    subscription: string().required('Subscription is required.'),
    resourceGroup: string().required('Resource group is required.'),
    location: string().required('Location is required.'),
    os: mixed().oneOf(['Linux', 'Windows']),
    cpu: number().required().min(1, 'CPU must be greater than or equal to 1.'),
    memory: number()
      .required()
      .min(1, 'Memory must be greater than or equal to 1GB.'),
    allocatePublicIP: boolean().required(),
    ports: portsSchema(),
    accessControl: accessControlSchema(isAdmin),
    restartPolicy: mixed().oneOf(['Always', 'OnFailure', 'Never']),
    enableGPU: boolean().required(),
    gpu: object().shape({
      count: number().required(),
      sku: mixed().oneOf(['V100']),
    }),
    virtualNetwork: string().when('allocatePublicIP', {
      is: false,
      then: string().required('Virtual network is required.'),
    }),
    subnet: string().when('allocatePublicIP', {
      is: false,
      then: string().required('Subnet is required.'),
    }),
    tags: array().of(
      object().shape({
        name: string()
          .required('Tag name is required')
          .max(512, 'The name must be 512 characters or less.'),
        value: string()
          .required('Tag value is required')
          .max(256, 'The value must be 256 characters or less.'),
      })
    ),
    enableVolumes: boolean()
      .required()
      .when('os', {
        is: 'Windows',
        then: boolean().test(
          'disabled-on-windows',
          'Volumes can only be enabled on Linux containers.',
          (value) => !value
        ),
      }),
    volumes: getVolumesValidation(),
  });
}
