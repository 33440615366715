import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isNil, omitBy } from 'lodash';

import { queryKeys } from '@/react/azure/queries/query-keys';
import { EnvironmentId } from '@/react/portainer/environments/types';
import PortainerError from '@/portainer/error';
import {
  ContainerGroup,
  ContainerInstanceFormValues,
  FileShareVolume,
  ResourceGroup,
  Subnet,
  SubnetDelegation,
  containerGroupApiVersion,
  virtualNetworkApiVersion,
} from '@/react/azure/types';
import { applyResourceControl } from '@/react/portainer/access-control/access-control.service';
import axios, { parseAxiosError } from '@/portainer/services/axios';

import {
  buildContainerGroupUrl,
  buildSubnetUrl,
  parseAzureAxiosError,
} from '../../queries/utils';
import { parseSubnetId } from '../../utils';

import { parseResourceGroupOptions } from './utils';

type MutationValues = {
  values: ContainerInstanceFormValues;
  subnet?: Subnet;
};

export function useCreateContainerInstance(
  resourceGroups: {
    [k: string]: ResourceGroup[];
  },
  environmentId: EnvironmentId
) {
  const queryClient = useQueryClient();
  return useMutation<ContainerGroup, unknown, MutationValues>(
    ({ values, subnet }) => {
      if (!values.subscription) {
        throw new PortainerError('subscription is required');
      }

      const subscriptionResourceGroup = parseResourceGroupOptions(
        values.subscription,
        resourceGroups
      );
      const resourceGroup = subscriptionResourceGroup.find(
        (r) => r.value === values.resourceGroup
      );
      if (!resourceGroup) {
        throw new PortainerError('resource group not found');
      }

      return createContainerGroup(
        values,
        environmentId,
        values.subscription,
        resourceGroup.label,
        subnet
      );
    },
    {
      async onSuccess(containerGroup, { values }) {
        const resourceControl = containerGroup.Portainer?.ResourceControl;
        if (!resourceControl) {
          throw new PortainerError('resource control expected after creation');
        }

        const accessControlData = values.accessControl;
        await applyResourceControl(accessControlData, resourceControl.Id);
        return queryClient.invalidateQueries(
          queryKeys.subscriptions(environmentId)
        );
      },
    }
  );
}

async function createContainerGroup(
  formValues: ContainerInstanceFormValues,
  environmentId: EnvironmentId,
  subscriptionId: string,
  resourceGroup: string,
  subnet?: Subnet
) {
  const { allocatePublicIP, name } = formValues;
  const subnetPayload = allocatePublicIP
    ? undefined
    : getSubnetPayload({
        allocatePublicIP,
        subnet,
        subscriptionId,
        resourceGroup,
      });

  // make sure the subnet has the required delegation (updateSubnetDelegation already has a try/catch block)
  if (subnetPayload) {
    const { virtualNetworkName, resourceGroupName } = parseSubnetId(
      subnetPayload.id
    );
    await updateSubnetDelegation({
      environmentId,
      subscriptionId,
      resourceGroup: resourceGroupName,
      virtualNetwork: virtualNetworkName,
      subnet: subnetPayload,
    });
  }

  const containerGroupPayload = transformToPayload(formValues);
  try {
    const { data } = await axios.put<ContainerGroup>(
      buildContainerGroupUrl(
        environmentId,
        subscriptionId,
        resourceGroup,
        name
      ),
      containerGroupPayload,
      { params: { 'api-version': containerGroupApiVersion } }
    );
    return data;
  } catch (e) {
    throw parseAxiosError(e, 'Unable to create container group');
  }
}

function transformToPayload(formValues: ContainerInstanceFormValues) {
  if (!formValues.location) {
    throw new Error('Location is required');
  }

  const ports = formValues.ports.filter((port) => port.port && port.protocol);
  const subnetIds =
    formValues.allocatePublicIP || !formValues.subnet
      ? undefined
      : [{ id: formValues.subnet }];
  const tags = formValues.tags.reduce<Record<string, string>>(
    (acc, { name, value }) => {
      acc[name] = value;
      return acc;
    },
    {}
  );
  const gpu = formValues.enableGPU ? formValues.gpu : undefined;
  const volumes: FileShareVolume[] = formValues.enableVolumes
    ? formValues.volumes.map((volume) => ({
        name: volume.shareName ?? '',
        azureFile: {
          sharename: volume.shareName?.trim() ?? '',
          storageAccountKey: volume.storageAccountKey?.trim() ?? '',
          storageAccountName: volume.storageAccountName?.trim() ?? '',
        },
      }))
    : [];
  const volumeMounts = formValues.enableVolumes
    ? formValues.volumes.map((volume) => ({
        name: volume.shareName?.trim() ?? '',
        mountPath: volume.mountPath?.trim() ?? '',
      }))
    : [];

  const payload: ContainerGroup = {
    name: formValues.name,
    location: formValues.location,
    tags,
    properties: {
      osType: formValues.os,
      containers: [
        {
          name: formValues.name,
          properties: {
            image: formValues.image,
            ports,
            resources: {
              requests: {
                cpu: formValues.cpu,
                memoryInGB: formValues.memory,
                gpu,
              },
            },
            volumeMounts,
          },
        },
      ],
      ipAddress: {
        type: formValues.allocatePublicIP ? 'Public' : 'Private',
        ports,
      },
      subnetIds,
      volumes,
    },
  };
  return omitBy<ContainerGroup>(payload, isNil);
}

function getContainerInstanceDelegation(
  subscriptionId: string,
  resourceGroup: string
): SubnetDelegation {
  return {
    name: 'Microsoft.ContainerInstance/containerGroups',
    id: `/subscriptions/${subscriptionId}/resourceGroup/${resourceGroup}/providers/Microsoft.Network/availableDelegations/Microsoft.ContainerInstance.containerGroups`,
    properties: {
      actions: ['Microsoft.Network/virtualNetworks/subnets/action'],
      serviceName: 'Microsoft.ContainerInstance/containerGroups',
    },
    type: 'Microsoft.Network/availableDelegations',
  };
}

async function updateSubnetDelegation({
  environmentId,
  subscriptionId,
  resourceGroup,
  virtualNetwork,
  subnet,
}: {
  environmentId: EnvironmentId;
  subscriptionId: string;
  resourceGroup: string;
  virtualNetwork?: string;
  subnet: Subnet;
}) {
  if (!virtualNetwork) {
    throw new Error('Virtual network is required');
  }

  try {
    await axios.put<Subnet>(
      buildSubnetUrl(
        environmentId,
        subscriptionId,
        resourceGroup,
        virtualNetwork,
        subnet.name
      ),
      subnet,
      {
        params: { 'api-version': virtualNetworkApiVersion },
      }
    );
  } catch (e) {
    throw parseAzureAxiosError(e, 'Unable to update subnet delegation');
  }
}

/**
 * Add the delegation to the subnet if it doesn't exist. If the delegation already exists, return undefined.
 */
function getSubnetPayload({
  subnet,
  subscriptionId,
  resourceGroup,
}: {
  allocatePublicIP: boolean;
  subnet?: Subnet;
  subscriptionId: string;
  resourceGroup: string;
}): Subnet | undefined {
  if (!subnet) {
    throw new Error('Subnet is required');
  }

  const delegation = getContainerInstanceDelegation(
    subscriptionId,
    resourceGroup
  );
  const isDelegationServiceFound = subnet.properties.delegations?.some(
    (d) => d.properties.serviceName === delegation.properties.serviceName
  );

  if (isDelegationServiceFound) {
    return undefined;
  }

  // add the delegation to the subnet if it doesn't exist
  const updatedSubnet = { ...subnet };
  updatedSubnet.properties.delegations = [
    ...(subnet.properties.delegations || []),
    delegation,
  ];
  return updatedSubnet;
}
