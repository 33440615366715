import { useQuery } from '@tanstack/react-query';

import axios from '@/portainer/services/axios';
import { EnvironmentId } from '@/react/portainer/environments/types';
import { withError } from '@/react-tools/react-query';

import { VirtualNetworksResponse, virtualNetworkApiVersion } from '../types';

import { buildVirtualNetworksUrl, parseAzureAxiosError } from './utils';
import { queryKeys } from './query-keys';

export function useVirtualNetworks(
  environmentId: EnvironmentId,
  subscriptionId: string
) {
  return useQuery(
    queryKeys.virtualNetworks(environmentId, subscriptionId),
    () => getVirtualNetworks(environmentId, subscriptionId),
    {
      enabled: !!subscriptionId,
      ...withError('Unable to retrieve Azure virtual networks'),
    }
  );
}

async function getVirtualNetworks(
  environmentId: EnvironmentId,
  subscriptionId: string
) {
  try {
    const { data } = await axios.get<VirtualNetworksResponse>(
      buildVirtualNetworksUrl(environmentId, subscriptionId),
      { params: { 'api-version': virtualNetworkApiVersion } }
    );
    return data.value;
  } catch (e) {
    throw parseAzureAxiosError(e, 'Unable to retrieve virtual networks');
  }
}
