import { Asterisk, Plus } from 'lucide-react';
import { KeyToPath, VolumeMount, Pod } from 'kubernetes-types/core/v1';

import { useEnvironmentId } from '@/react/hooks/useEnvironmentId';
import { useSecrets } from '@/react/kubernetes/configs/secret.service';

import { Icon } from '@@/Icon';
import { Link } from '@@/Link';

import { Application } from '../../types';
import { SecretLink } from '../SecretLink';
import { applicationIsKind } from '../../utils';

import { getSecret } from './utils';

export type AppVolumeConfig = {
  volumeConfigName: string | undefined;
  containerVolumeMount: VolumeMount | undefined;
  containerName: string;
  isInitContainer: boolean;
  item: KeyToPath;
};

type ConfigsTableProps = {
  namespace: string;
  app?: Application;
};

export function ApplicationVolumeConfigsTable({
  namespace,
  app,
}: ConfigsTableProps) {
  const containerVolumeConfigs = getApplicationVolumeConfigs(app);

  if (containerVolumeConfigs.length === 0) {
    return null;
  }

  return (
    <table className="table">
      <tbody>
        <tr className="text-muted">
          <td className="w-1/4">Container</td>
          <td className="w-1/4">Configuration path</td>
          <td className="w-1/4">Value</td>
          <td className="w-1/4">Configuration</td>
        </tr>
        {containerVolumeConfigs.map((appVolumeConfig) => (
          <ConfigRow
            key={`${appVolumeConfig.item.path}-${appVolumeConfig.volumeConfigName}`}
            appVolumeConfig={appVolumeConfig}
            namespaceName={namespace}
          />
        ))}
      </tbody>
    </table>
  );
}

type ConfigRowProps = {
  appVolumeConfig: AppVolumeConfig;
  namespaceName: string;
};

function ConfigRow({ appVolumeConfig, namespaceName }: ConfigRowProps) {
  const {
    containerVolumeMount,
    isInitContainer,
    containerName,
    item,
    volumeConfigName,
  } = appVolumeConfig;

  const environmentId = useEnvironmentId();
  const { data: secrets } = useSecrets(environmentId, namespaceName);
  const secret = getSecret(secrets, volumeConfigName);

  return (
    <tr>
      <td>
        {containerName}
        {isInitContainer && (
          <span>
            <Icon icon={Asterisk} />(
            <a
              href="https://kubernetes.io/docs/concepts/workloads/pods/init-containers/"
              target="_blank"
              rel="noopener noreferrer"
            >
              init container
            </a>
            )
          </span>
        )}
      </td>
      <td>
        {item.path
          ? `${containerVolumeMount?.mountPath}/${item.path}`
          : `${containerVolumeMount?.mountPath}`}
      </td>
      <td>
        {item.key && (
          <div className="flex items-center">
            <Icon icon={Plus} className="!mr-1" />
            {item.key}
          </div>
        )}
        {!item.key && '-'}
      </td>
      <td>
        {secret ? (
          <SecretLink
            name={volumeConfigName}
            namespace={namespaceName}
            secret={secret}
          />
        ) : (
          <Link
            className="flex items-center"
            to="kubernetes.configmaps.configmap"
            params={{ name: volumeConfigName, namespace: namespaceName }}
            data-cy={`config-link-${volumeConfigName}`}
          >
            <Icon icon={Plus} className="!mr-1" />
            {volumeConfigName}
          </Link>
        )}
        {!volumeConfigName && '-'}
      </td>
    </tr>
  );
}

// getApplicationVolumeConfigs returns a list of volume configs / secrets for each container and each item within the matching volume
function getApplicationVolumeConfigs(app?: Application): AppVolumeConfig[] {
  if (!app) {
    return [];
  }

  const podSpec = applicationIsKind<Pod>('Pod', app)
    ? app.spec
    : app.spec?.template?.spec;
  const appContainers = podSpec?.containers || [];
  const appInitContainers = podSpec?.initContainers || [];
  const appVolumes = podSpec?.volumes || [];
  const allContainers = [...appContainers, ...appInitContainers];

  const appVolumeConfigs = allContainers.flatMap((container) => {
    // for each container, get the volume mount paths
    const matchingVolumes = appVolumes
      // filter app volumes by config map or secret
      .filter((volume) => volume.configMap || volume.secret)
      .flatMap((volume) => {
        // flatten by volume items if there are any
        const volConfigMapItems =
          volume.configMap?.items || volume.secret?.items || [];
        const volumeConfigName =
          volume.configMap?.name || volume.secret?.secretName;
        const containerVolumeMount = container.volumeMounts?.find(
          (volumeMount) => volumeMount.name === volume.name
        );
        if (volConfigMapItems.length === 0) {
          return [
            {
              volumeConfigName,
              containerVolumeMount,
              containerName: container.name,
              isInitContainer: appInitContainers.includes(container),
              item: {} as KeyToPath,
            },
          ];
        }
        // if there are items, return a volume config for each item
        return volConfigMapItems.map((item) => ({
          volumeConfigName,
          containerVolumeMount,
          containerName: container.name,
          isInitContainer: appInitContainers.includes(container),
          item,
        }));
      })
      // only return the app volumes where the container volumeMounts include the volume name (from map step above)
      .filter((volume) => volume.containerVolumeMount);
    return matchingVolumes;
  });

  return appVolumeConfigs;
}
