import { FormikErrors } from 'formik';

import { ButtonSelector } from '@@/form-components/ButtonSelector/ButtonSelector';
import { FormError } from '@@/form-components/FormError';
import { InputGroup } from '@@/form-components/InputGroup';
import { InputList } from '@@/form-components/InputList';
import { ItemProps } from '@@/form-components/InputList/InputList';

import { PortMapping, Protocol } from '../../types';

interface Props {
  value: PortMapping[];
  onChange?(value: PortMapping[]): void;
  errors?: FormikErrors<PortMapping>[] | string | string[];
  disabled?: boolean;
  readOnly?: boolean;
}

export function PortsMappingField({
  value,
  onChange = () => {},
  errors,
  disabled,
  readOnly,
}: Props) {
  return (
    <>
      <InputList<PortMapping>
        label="Ports"
        value={value}
        onChange={onChange}
        addLabel="Add port"
        itemBuilder={() => ({
          port: 80,
          protocol: 'TCP',
        })}
        item={Item}
        errors={errors}
        disabled={disabled}
        readOnly={readOnly}
        data-cy="aci-ports-mapping"
      />
      {typeof errors === 'string' && (
        <div className="form-group col-md-12">
          <FormError>{errors}</FormError>
        </div>
      )}
    </>
  );
}

function Item({
  onChange,
  item,
  error,
  disabled,
  readOnly,
  index,
}: ItemProps<PortMapping>) {
  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2">
        <InputGroup size="small">
          <InputGroup.Addon>Port</InputGroup.Addon>
          <InputGroup.Input
            placeholder="e.g. 80"
            value={item.port}
            onChange={(e) =>
              handleChange('port', parseInt(e.target.value || '0', 10))
            }
            disabled={disabled}
            readOnly={readOnly}
            type="number"
            data-cy={`aci-ports-mapping-port-input_${index}`}
          />
        </InputGroup>

        <ButtonSelector<Protocol>
          onChange={(value) => handleChange('protocol', value)}
          value={item.protocol}
          options={[{ value: 'TCP' }, { value: 'UDP' }]}
          disabled={disabled}
          readOnly={readOnly}
        />
      </div>
      {!!error && <FormError>{Object.values(error)[0]}</FormError>}
    </div>
  );

  function handleChange(name: keyof PortMapping, value: string | number) {
    onChange({ ...item, [name]: value });
  }
}
