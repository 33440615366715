import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getAllSettledItems } from '@/portainer/helpers/promise-utils';
import { notifyError, notifySuccess } from '@/portainer/services/notifications';
import { EnvironmentId } from '@/react/portainer/environments/types';
import { withError } from '@/react-tools/react-query';
import axios, { parseAxiosError } from '@/portainer/services/axios';
import { pluralize } from '@/portainer/helpers/strings';

import { ContainerGroup, containerGroupApiVersion } from '../types';

import { queryKeys } from './query-keys';

export function useRemoveContainerGroups(environmentId: EnvironmentId) {
  const queryClient = useQueryClient();
  return useMutation(
    (containerGroups: ContainerGroup[]) =>
      deleteContainerGroups(environmentId, containerGroups),
    {
      onSuccess({ fulfilledItems, rejectedItems }) {
        // one error notification per rejected item
        rejectedItems.forEach(({ item, reason }) => {
          notifyError(
            `Failed to remove container '${item.name}'`,
            new Error(reason)
          );
        });

        // one success notification for all fulfilled items
        if (fulfilledItems.length) {
          notifySuccess(
            `${pluralize(
              fulfilledItems.length,
              'Container'
            )} successfully removed`,
            fulfilledItems.map((item) => item.name).join(', ')
          );
        }
        queryClient.invalidateQueries(queryKeys.subscriptions(environmentId));
      },
      ...withError('Unable to remove container groups'),
    }
  );
}

async function deleteContainerGroups(
  environmentId: EnvironmentId,
  containerGroups: ContainerGroup[]
) {
  return getAllSettledItems(containerGroups, deleteContainerGroup);

  async function deleteContainerGroup(containerGroup: ContainerGroup) {
    const containerGroupId = containerGroup.id;
    try {
      await axios.delete(
        `/endpoints/${environmentId}/azure${containerGroupId}`,
        {
          params: { 'api-version': containerGroupApiVersion },
        }
      );
    } catch (e) {
      throw parseAxiosError(e, 'Unable to remove container');
    }
  }
}
