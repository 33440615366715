import { useFormikContext } from 'formik';

import { ContainerInstanceFormValues, Tag } from '@/react/azure/types';

import { InputList, ItemProps } from '@@/form-components/InputList';
import { InputGroup } from '@@/form-components/InputGroup';
import { FormError } from '@@/form-components/FormError';
import { isErrorType } from '@@/form-components/formikUtils';

type Props = {
  disabled?: boolean;
  readOnly?: boolean;
};

export function TagsField({ disabled, readOnly }: Props) {
  const { values, setFieldValue, errors } =
    useFormikContext<ContainerInstanceFormValues>();
  const tagErrors = errors.tags;
  return (
    <InputList<Tag>
      label="Tags"
      value={values.tags}
      onChange={(tags) => setFieldValue('tags', tags)}
      addLabel="Add tag"
      itemBuilder={() => ({
        name: '',
        value: '',
      })}
      item={Item}
      errors={tagErrors}
      disabled={disabled}
      readOnly={readOnly}
      data-cy="aci-tags"
    />
  );
}

function Item({
  onChange,
  item,
  error,
  disabled,
  readOnly,
  index,
}: ItemProps<Tag>) {
  const formikError = isErrorType(error) ? error : undefined;
  return (
    <div className="flex items-start gap-2">
      <div>
        <InputGroup size="small">
          <InputGroup.Addon>Name</InputGroup.Addon>
          <InputGroup.Input
            placeholder="e.g. project"
            value={item.name}
            onChange={(e) => handleChange('name', e.target.value)}
            disabled={disabled}
            readOnly={readOnly}
            type="text"
            data-cy={`aci-tag-name-input_${index}`}
          />
        </InputGroup>
        {!!formikError?.name && <FormError>{formikError.name}</FormError>}
      </div>
      <div>
        <InputGroup size="small">
          <InputGroup.Addon>Value</InputGroup.Addon>
          <InputGroup.Input
            placeholder="e.g. analytics"
            value={item.value}
            onChange={(e) => handleChange('value', e.target.value)}
            disabled={disabled}
            readOnly={readOnly}
            type="text"
            data-cy={`aci-tag-value-input_${index}`}
          />
        </InputGroup>
        {!!formikError?.value && <FormError>{formikError.value}</FormError>}
      </div>
    </div>
  );

  function handleChange(name: keyof Tag, value: string | number) {
    onChange({ ...item, [name]: value });
  }
}
