import { useField } from 'formik';
import { Info } from 'lucide-react';

import { useCategory } from '@/react/edge/edge-configurations/CreateView/useCategory';

import { FormSection } from '@@/form-components/FormSection';
import { TextTip } from '@@/Tip/TextTip';
import { FormControl } from '@@/form-components/FormControl';
import { Option, PortainerSelect } from '@@/form-components/PortainerSelect';

import { FormValues, FormValuesEdgeConfigurationMatchingRule } from './types';

const options: Option<FormValuesEdgeConfigurationMatchingRule>[] = [
  {
    label: 'Match file name with Portainer Edge ID',
    value: FormValuesEdgeConfigurationMatchingRule.MatchFile,
  },
  {
    label: 'Match folder name with Portainer Edge ID',
    value: FormValuesEdgeConfigurationMatchingRule.MatchFolder,
  },
];

export function DeviceSpecificConfigurationFields() {
  const [category] = useCategory();

  const [
    { value: matchingRule },
    { error: deviceError },
    { setValue: setMatchingRule },
  ] = useField<FormValues['matchingRule']>('matchingRule');

  return (
    <FormSection title="Target devices">
      <TextTip color="blue" icon={Info} className="mb-2">
        Select which rule to use when matching {category} with Portainer Edge ID
        either on a per-device basis or group-wide with an Edge Group. Only
        configurations that match the selected rule will be accessible through
        their corresponding paths. Deployments that rely on accessing the
        configuration may experience errors.
      </TextTip>
      <FormControl label="Matching rule" errors={deviceError}>
        <PortainerSelect
          onChange={setMatchingRule}
          value={matchingRule}
          options={options}
          data-cy="edge-configuration-matching-rule-selector"
        />
      </FormControl>
    </FormSection>
  );
}
