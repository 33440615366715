import { useQuery } from '@tanstack/react-query';

import { Environment } from '@/react/portainer/environments/types';
import { Registry } from '@/react/portainer/registries/types/registry';

import { manifestsToTag } from '../ItemView/TagsDatatable/manifestsToTag';
import { RepositoryTagViewModel } from '../ItemView/TagsDatatable/view-model';

import { getTagManifestV2 } from './manifest.service';
import { ImageConfigs, getRegistryBlob } from './getRegistryBlobs';
import { queryKeys } from './queryKeys';

interface Params {
  registryId: Registry['Id'];
  repository: string;
  environmentId?: Environment['Id'];
  tag: string;
}

export function useTagDetails<T = RepositoryTagViewModel>(
  params: Params,
  {
    staleTime = 0,
    select,
  }: { select?: (model: RepositoryTagViewModel) => T; staleTime?: number } = {}
) {
  return useQuery({
    queryKey: queryKeys.tagDetails(params),
    queryFn: () => getTagDetails(params),
    staleTime,
    select,
  });
}

export async function getTagDetails({
  registryId,
  environmentId,
  repository,
  tag,
}: Params) {
  const params = {
    id: registryId,
    endpointId: environmentId,
    repository,
    tag,
  };

  const v2 = await getTagManifestV2(params);

  let imageConfigs: ImageConfigs | undefined;
  try {
    imageConfigs = await getRegistryBlob({
      digest: v2.config.digest,
      ...params,
    });
  } catch (e) {
    // empty
  }

  const data: { v2: typeof v2; imageConfigs?: ImageConfigs } = {
    v2,
    imageConfigs,
  };

  const tagDetails = manifestsToTag(data);
  tagDetails.Name = tagDetails.Name || tag;
  return tagDetails;
}
