import { HistoryIcon } from 'lucide-react';

import {
  refreshableSettings,
  createPersistedStore,
  BasicTableSettings,
  RefreshableTableSettings,
} from '@@/datatables/types';
import { useTableState } from '@@/datatables/useTableState';
import { Datatable, TableSettingsMenu } from '@@/datatables';
import { TableSettingsMenuAutoRefresh } from '@@/datatables/TableSettingsMenuAutoRefresh';

import { useContainerGroup } from '../../../queries/useContainerGroup';
import { ContainerEvent } from '../../../types';
import { getAllEvents } from '../utils';

import { columns } from './columns';

interface TableSettings extends BasicTableSettings, RefreshableTableSettings {}

function createStore(storageKey: string) {
  return createPersistedStore<TableSettings>(
    storageKey,
    { id: 'Date', desc: true },
    (set) => ({
      ...refreshableSettings(set),
    })
  );
}
const storageKey = 'azure.container-instances.events-datatable';
const settingsStore = createStore(storageKey);

type Props = {
  environmentId: number;
  subscriptionId: string;
  resourceGroupId: string;
  containerGroupId: string;
};

export function EventsDatatable({
  environmentId,
  subscriptionId,
  resourceGroupId,
  containerGroupId,
}: Props) {
  const tableState = useTableState(settingsStore, storageKey);
  const eventsQuery = useContainerGroup<ContainerEvent[]>(
    environmentId,
    subscriptionId,
    resourceGroupId,
    containerGroupId,
    {
      select: getAllEvents,
      refetchInterval: tableState.autoRefreshRate * 1000,
    }
  );

  return (
    <Datatable<ContainerEvent>
      dataset={eventsQuery.data ?? []}
      columns={columns}
      settingsManager={tableState}
      isLoading={eventsQuery.isLoading}
      title="Events"
      titleIcon={HistoryIcon}
      getRowId={(row) => `${row.name}-${row.firstTimestamp}`}
      disableSelect
      renderTableSettings={() => (
        <TableSettingsMenu>
          <TableSettingsMenuAutoRefresh
            value={tableState.autoRefreshRate}
            onChange={(value) => tableState.setAutoRefreshRate(value)}
          />
        </TableSettingsMenu>
      )}
      data-cy="container-group-events-datatable"
    />
  );
}
