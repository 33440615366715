import { useMemo } from 'react';

import { name } from './name';
import { location } from './location';
import { ports } from './ports';
import { ownership } from './ownership';
import { tags } from './tags';
import { getState } from './state';
import { resourceGroup } from './resourceGroup';

export function useColumns(isStatusLoading: boolean) {
  return useMemo(
    () => [
      name,
      resourceGroup,
      location,
      getState(isStatusLoading),
      ports,
      tags,
      ownership,
    ],
    [isStatusLoading]
  );
}
