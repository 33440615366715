import { AxiosError } from 'axios';

import { EnvironmentId } from '@/react/portainer/environments/types';
import { parseAxiosError } from '@/portainer/services/axios';

export function buildSubscriptionsUrl(
  environmentId: EnvironmentId,
  id?: string
) {
  let url = `/endpoints/${environmentId}/azure/subscriptions`;
  if (id) {
    url += `/${id}`;
  }

  return url;
}

export function buildResourceGroupUrl(
  environmentId: EnvironmentId,
  subscriptionId: string,
  resourceGroupName?: string
) {
  let url = `${buildSubscriptionsUrl(
    environmentId,
    subscriptionId
  )}/resourcegroups`;

  if (resourceGroupName) {
    url += `/${resourceGroupName}`;
  }

  return url;
}

export function buildContainerGroupUrl(
  environmentId: EnvironmentId,
  subscriptionId: string,
  resourceGroupName?: string,
  containerGroupName?: string
) {
  let url = buildSubscriptionsUrl(environmentId, subscriptionId);

  if (resourceGroupName) {
    url += `/resourceGroups/${resourceGroupName}`;
  }

  url += `/providers/Microsoft.ContainerInstance/containerGroups`;

  if (containerGroupName) {
    url += `/${containerGroupName}`;
  }

  return url;
}

export function buildVirtualNetworksUrl(
  environmentId: EnvironmentId,
  subscriptionId: string
) {
  let url = `${buildSubscriptionsUrl(environmentId, subscriptionId)}`;
  url += `/providers/Microsoft.Network/virtualNetworks`;

  return url;
}

export function buildSubnetUrl(
  environmentId: EnvironmentId,
  subscriptionId: string,
  resourceGroupName: string,
  virtualNetworkName: string,
  subnetName?: string
) {
  let url = buildSubscriptionsUrl(environmentId, subscriptionId);
  url += `/resourceGroups/${resourceGroupName}/providers/Microsoft.Network/virtualNetworks/${virtualNetworkName}/subnets`;

  if (subnetName) {
    url += `/${subnetName}`;
  }

  return url;
}

/**
 * remove all whitespace and make lowercase
 */
export function normalizeLocation(location: string) {
  return location.replace(/\s+/g, '').toLowerCase();
}

type AzureErrorResponse = {
  error?: {
    code?: string;
    message?: string;
  };
};

export function azureErrorParser(axiosError: AxiosError) {
  if (!axiosError.response) {
    const error = new Error('Failed azure request');
    return {
      error,
      details: axiosError.message,
    };
  }

  const responseData = axiosError.response.data as AzureErrorResponse;
  const message =
    responseData?.error?.message ||
    `Failed azure request: ${axiosError.response?.statusText}`;

  return {
    error: new Error(message),
    details: message,
  };
}

export function parseAzureAxiosError(err: unknown, msg = '') {
  return parseAxiosError(err, msg, azureErrorParser);
}
