import { CellContext } from '@tanstack/react-table';

import { ContainerGroup } from '@/react/azure/types';
import { getSchemeFromPort } from '@/react/common/network-utils';
import { getPorts } from '@/react/azure/utils';

import { ExternalLink } from '@@/ExternalLink';

import { columnHelper } from './helper';

export const ports = columnHelper.accessor(getPorts, {
  header: 'Published Ports',
  cell: PortsCell,
  id: 'ports',
});

function PortsCell({
  getValue,
  row: { original: container },
}: CellContext<ContainerGroup, ReturnType<typeof getPorts>>) {
  const ports = getValue();

  const ip = container.properties?.ipAddress
    ? container.properties.ipAddress.ip
    : '';
  if (ports.length === 0 || !ip) {
    return '-';
  }

  return ports.map((port) => {
    const scheme = getSchemeFromPort(port.host);
    return (
      <ExternalLink
        to={`${scheme}://${ip}:${port.host}`}
        data-cy="published-port-link"
        key={port.host}
      >
        {ip}:{port.host}
      </ExternalLink>
    );
  });
}
