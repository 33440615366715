import { useRouter } from '@uirouter/react';

import { AddButton } from '@@/buttons';
import { DeleteButton } from '@@/buttons/DeleteButton';

import { Credential } from '../../types';
import { useDeleteCredentialMutation } from '../../cloudSettings.service';

interface Props {
  selectedItems: Credential[];
}

export function CredentialsDatatableActions({ selectedItems }: Props) {
  const router = useRouter();
  const deleteCredentialMutation = useDeleteCredentialMutation();

  return (
    <>
      <DeleteButton
        disabled={selectedItems.length < 1}
        confirmMessage="Are you sure you want to remove the selected credentials?"
        onConfirmed={() => onDeleteClick(selectedItems)}
        data-cy="credentials-deleteButton"
      />

      <AddButton
        to="portainer.settings.sharedcredentials.addCredential"
        data-cy="credentials-addButton"
      >
        Add credentials
      </AddButton>
    </>
  );

  async function onDeleteClick(selectedItems: Credential[]) {
    selectedItems.forEach((item) => {
      deleteCredentialMutation.mutate(item);
    });

    router.stateService.reload();
  }
}
