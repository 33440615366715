import { useDeleteGitCredentialMutation } from '@/react/portainer/account/git-credentials/git-credentials.service';
import { GitCredential } from '@/react/portainer/account/git-credentials/types';

import { AddButton } from '@@/buttons';
import { DeleteButton } from '@@/buttons/DeleteButton';

interface Props {
  selectedItems: GitCredential[];
}

export function GitCredentialsDatatableActions({ selectedItems }: Props) {
  const deleteGitCredentialMutation = useDeleteGitCredentialMutation();

  return (
    <>
      <DeleteButton
        disabled={selectedItems.length === 0}
        confirmMessage={`Are you sure you want to remove the selected git ${
          selectedItems.length > 1 ? 'credentials' : 'credential'
        }?`}
        onConfirmed={() => onDeleteClick(selectedItems)}
        data-cy="credentials-deleteButton"
      />

      <AddButton
        to="portainer.account.gitCreateGitCredential"
        data-cy="credentials-addButton"
      >
        Add git credential
      </AddButton>
    </>
  );

  async function onDeleteClick(selectedItems: GitCredential[]) {
    selectedItems.forEach((item) => {
      deleteGitCredentialMutation.mutate(item);
    });
  }
}
