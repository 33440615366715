const autoNgTemplateLoaderTemplate1 = require('./views/repositories/edit/template.html');

import { AccessHeaders } from '../authorization-guard';

angular.module('portainer.registrymanagement', []).config(config);

/* @ngInject */
function config($stateRegistryProvider) {
  const registries = {
    name: 'portainer.registries',
    url: '/registries',
    views: {
      'content@': {
        component: 'registriesView',
      },
    },
    data: {
      docs: '/admin/registries',
      access: AccessHeaders.Admin,
    },
  };

  const registryCreation = {
    name: 'portainer.registries.new',
    url: '/new',
    views: {
      'content@': {
        component: 'createRegistry',
      },
    },
    data: {
      docs: '/admin/registries/add',
    },
  };

  const registry = {
    name: 'portainer.registries.registry',
    url: '/:id',
    views: {
      'content@': {
        component: 'editRegistry',
      },
    },
    data: {
      docs: '/admin/registries/edit',
    },
  };

  const registryConfiguration = {
    name: 'portainer.registries.registry.configure',
    url: '/configure',
    views: {
      'content@': {
        component: 'configureRegistryView',
      },
    },
  };

  const registryRepositories = {
    name: 'portainer.registries.registry.repositories',
    url: '/repositories?endpointId',
    views: {
      'content@': {
        component: 'registryRepositoriesView',
      },
    },
    data: {
      docs: '/admin/registries/browse',
      access: undefined,
    },
  };

  const registryRepositoryTags = {
    name: 'portainer.registries.registry.repository',
    url: '/:repository?endpointId',
    views: {
      'content@': {
        templateUrl: autoNgTemplateLoaderTemplate1,
        controller: 'RegistryRepositoryController',
      },
    },
    data: {
      access: undefined,
    },
  };

  const registryRepositoryTag = {
    name: 'portainer.registries.registry.repository.tag',
    url: '/:tag',
    views: {
      'content@': {
        component: 'registryRepositoryTagView',
      },
    },
    data: {
      access: undefined,
    },
  };

  $stateRegistryProvider.register(registries);
  $stateRegistryProvider.register(registry);
  $stateRegistryProvider.register(registryConfiguration);
  $stateRegistryProvider.register(registryCreation);
  $stateRegistryProvider.register(registryRepositories);
  $stateRegistryProvider.register(registryRepositoryTags);
  $stateRegistryProvider.register(registryRepositoryTag);
}
