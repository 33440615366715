import { ListRestart } from 'lucide-react';

import { useEnvironmentId } from '@/react/hooks/useEnvironmentId';
import { useEnvironment } from '@/react/portainer/environments/queries';
import { notifySuccess } from '@/portainer/services/notifications';
import { useInfo } from '@/react/docker/proxy/queries/useInfo';

import { TooltipWithChildren } from '@@/Tip/TooltipWithChildren';
import { Button } from '@@/buttons';

import { ResourceType } from './types';
import { useClearImagesStatus } from './queries/useClearImagesStatus';

type Props = {
  resourceType: ResourceType;
  size?: 'sm' | 'md';
};

export function RefreshImageStatusButton({ resourceType, size = 'sm' }: Props) {
  const environmentId = useEnvironmentId();
  const environmentQuery = useEnvironment(environmentId);
  const enableImageStatus = environmentQuery.data?.EnableImageNotification;
  const swarmIdQuery = useInfo(environmentId, {
    select: (info) => info?.Swarm?.Cluster?.ID,
  });

  const clearImageStatusMutation = useClearImagesStatus({
    resourceType,
    swarmId: swarmIdQuery.data,
  });

  if (
    !enableImageStatus ||
    environmentQuery.isLoading ||
    swarmIdQuery.isLoading
  ) {
    return null;
  }

  return (
    <>
      {size === 'sm' && (
        <TooltipWithChildren message="Reload image up to date indicators">
          {/* add the div to avoid a tippy tooltip error */}
          <div>
            <Button
              color="default"
              data-cy="refresh-image-status-button"
              className="btn-only-icon !ml-0 h-[30px] !px-2"
              icon={ListRestart}
              onClick={onClick}
            />
          </div>
        </TooltipWithChildren>
      )}
      {size === 'md' && (
        <Button
          color="default"
          className="!ml-0 h-[30px]"
          icon={ListRestart}
          onClick={onClick}
          data-cy="refresh-image-status-button"
        >
          Reload image indicators
        </Button>
      )}
    </>
  );

  function onClick() {
    // clearImageStatusMutation should wait for the resources to refresh before notifying the user the image up to date indicators are refreshed
    // putting on success here after calling mutate will cause the notification to be shown after the resources are refreshed
    // see the following for info:
    // - https://tkdodo.eu/blog/mastering-mutations-in-react-query#awaited-promises (implemented in out withInvalidate function)
    // - https://tanstack.com/query/v3/docs/react/guides/mutations#mutation-side-effects (search 'trigger additional callbacks')
    clearImageStatusMutation.mutate(undefined, {
      onSuccess: () => {
        notifySuccess('Success', 'Reloaded image up to date indicators');
      },
    });
  }
}
