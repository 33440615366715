import { useCurrentStateAndParams, useRouter } from '@uirouter/react';
import { useQueryClient } from '@tanstack/react-query';
import { AlertTriangle, Box, HistoryIcon, Settings } from 'lucide-react';

import { useEnvironmentId } from '@/react/hooks/useEnvironmentId';
import { AccessControlPanel } from '@/react/portainer/access-control/AccessControlPanel/AccessControlPanel';
import { ResourceControlViewModel } from '@/react/portainer/access-control/models/ResourceControlViewModel';
import { ResourceControlType } from '@/react/portainer/access-control/types';
import { useContainerGroup } from '@/react/azure/queries/useContainerGroup';
import { useResourceGroup } from '@/react/azure/queries/useResourceGroup';
import { parseContainerGroupId } from '@/react/azure/utils';
import { Authorized } from '@/react/hooks/useUser';

import { Widget, WidgetBody, WidgetTabs, WidgetTitle } from '@@/Widget';
import { PageHeader } from '@@/PageHeader';
import { findSelectedTabIndex, Tab } from '@@/Widget/WidgetTabs';
import { Badge } from '@@/Badge';
import { Icon } from '@@/Icon';

import { ContainerActions } from '../ContainerActions';

import { ContainerSummaryWidget } from './ContainerSummaryWidget';
import { EventsDatatable } from './EventsDatatable/EventsDatatable';
import { useEventWarningCount } from './utils';

export function ItemView() {
  const stateAndParams = useCurrentStateAndParams();
  const {
    params: { id },
  } = stateAndParams;
  const {
    subscriptionId,
    resourceGroupName: resourceGroupId,
    containerGroupId,
  } = parseContainerGroupId(id);
  const environmentId = useEnvironmentId();
  const router = useRouter();

  const queryClient = useQueryClient();

  const resourceGroupQuery = useResourceGroup(
    environmentId,
    subscriptionId,
    resourceGroupId
  );
  const resourceGroup = resourceGroupQuery.data;

  const containerQuery = useContainerGroup(
    environmentId,
    subscriptionId,
    resourceGroupId,
    containerGroupId
  );
  const container = containerQuery.data;

  const resourceControl = containerQuery.data?.Portainer?.ResourceControl
    ? new ResourceControlViewModel(
        containerQuery.data?.Portainer.ResourceControl
      )
    : undefined;

  const containerEventWarningCount = useEventWarningCount(container);

  const tabs: Tab[] = [
    {
      name: 'Container',
      icon: Box,
      widget: (
        <div className="row">
          <div className="col-sm-12">
            <ContainerSummaryWidget />
          </div>
        </div>
      ),
      selectedTabParam: 'container',
    },
    {
      name: (
        <div className="flex items-center gap-x-2">
          Events
          {containerEventWarningCount >= 1 && (
            <Badge type="warnSecondary">
              <Icon icon={AlertTriangle} className="!mr-1" />
              {containerEventWarningCount}
            </Badge>
          )}
        </div>
      ),
      icon: HistoryIcon,
      widget: (
        <EventsDatatable
          environmentId={environmentId}
          subscriptionId={subscriptionId}
          resourceGroupId={resourceGroupId}
          containerGroupId={containerGroupId}
        />
      ),
      selectedTabParam: 'events',
    },
  ];
  const currentTabIndex = findSelectedTabIndex(stateAndParams, tabs);

  return (
    <>
      <PageHeader
        title="Container Instance details"
        breadcrumbs={[
          { link: 'azure.containerinstances', label: 'Container instances' },
          { label: container?.name ?? '' },
        ]}
        reload
      />
      <>
        <WidgetTabs tabs={tabs} currentTabIndex={currentTabIndex} />
        {tabs[currentTabIndex].widget}
        <Authorized
          authorizations={[
            'AzureContainerGroupAction',
            'AzureContainerGroupDelete',
          ]}
        >
          <div className="row">
            <div className="col-sm-12">
              <Widget>
                <WidgetTitle icon={Settings} title="Actions" />
                <WidgetBody>
                  {containerQuery.data ? (
                    <ContainerActions
                      selectedItems={[containerQuery.data]}
                      environmentId={environmentId}
                      onDeleteSuccess={() =>
                        router.stateService.go('azure.containerinstances')
                      }
                    />
                  ) : null}
                </WidgetBody>
              </Widget>
            </div>
          </div>
        </Authorized>
        <AccessControlPanel
          onUpdateSuccess={() =>
            queryClient.invalidateQueries([
              'azure',
              environmentId,
              'subscriptions',
              subscriptionId,
              'resourceGroups',
              resourceGroup?.name,
              'containerGroups',
              container?.name,
            ])
          }
          resourceId={id}
          resourceControl={resourceControl}
          resourceType={ResourceControlType.ContainerGroup}
          environmentId={environmentId}
        />
      </>
    </>
  );
}
