import { Box, Package } from 'lucide-react';

import { useEnvironmentId } from '@/react/hooks/useEnvironmentId';
import Subscription from '@/assets/ico/subscription.svg?c';

import { PageHeader } from '@@/PageHeader';
import { DashboardItem } from '@@/DashboardItem';
import { DashboardGrid } from '@@/DashboardItem/DashboardGrid';

import { useResourceGroups } from '../queries/useResourceGroups';
import { useSubscriptions } from '../queries/useSubscriptions';
import { useContainerGroups } from '../queries/useContainerGroups';

export function DashboardView() {
  const environmentId = useEnvironmentId();

  const subscriptionsQuery = useSubscriptions(environmentId);

  const resourceGroupsQuery = useResourceGroups(
    environmentId,
    subscriptionsQuery.data
  );

  const containerGroupsQuery = useContainerGroups(
    environmentId,
    subscriptionsQuery.data,
    subscriptionsQuery.isSuccess
  );

  const subscriptionsCount = subscriptionsQuery.data?.length;
  const resourceGroupsCount = Object.values(resourceGroupsQuery.data).flatMap(
    (x) => Object.values(x)
  ).length;
  const containerGroupsCount = containerGroupsQuery.data.length;

  return (
    <>
      <PageHeader title="Home" breadcrumbs="Dashboard" reload />

      <div className="col-sm-12 gap-y-5">
        <DashboardGrid>
          <DashboardItem
            value={subscriptionsCount}
            data-cy="subscriptions-count"
            isLoading={subscriptionsQuery.isLoading}
            isRefetching={subscriptionsQuery.isRefetching}
            icon={Subscription}
            type="Subscription"
          />
          <DashboardItem
            value={resourceGroupsCount}
            data-cy="resource-groups-count"
            isLoading={resourceGroupsQuery.isLoading}
            isRefetching={resourceGroupsQuery.isRefetching}
            icon={Package}
            type="Resource Group"
          />
          <DashboardItem
            value={containerGroupsCount}
            data-cy="container-groups-count"
            isLoading={containerGroupsQuery.isLoading}
            isRefetching={containerGroupsQuery.isRefetching}
            icon={Box}
            type="Container Instance"
            to="azure.containerinstances"
          />
        </DashboardGrid>
      </div>
    </>
  );
}
