import { Loader2 } from 'lucide-react';

import { statusColor } from '@/react/azure/utils';

import { StatusBadge } from '@@/StatusBadge';
import { Icon } from '@@/Icon';

import { columnHelper } from './helper';

export function getState(isStatusLoading: boolean) {
  return columnHelper.accessor('properties.instanceView.state', {
    header: 'State',
    // use row instead of getState() to avoid undefined warning from getValue(), while the state is loading
    cell: ({ row: { original: container } }) => {
      const status = container.properties?.instanceView?.state || 'Unknown';
      if (typeof status !== 'string') {
        return '-';
      }
      if (isStatusLoading) {
        return <Icon icon={Loader2} className="animate-spin-slow" />;
      }
      return <StatusBadge color={statusColor[status]}>{status}</StatusBadge>;
    },
  });
}
